import React from "react";
import {useDataProvider} from "react-admin";
import GetApp from "@material-ui/icons/GetApp";
import downloadFromB64 from "./common/downloadFromB64";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button"


const DownloadLeads: React.FC = (props) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const dataProvider = useDataProvider();
    const downloadLeads = async () => {
        setIsLoading(true);
        try {
            const d = new Date();
            const result = await dataProvider.getWithoutQuery("exportleads");
            await downloadFromB64((result as any).data.b64, `leads_export_${d.getUTCFullYear()}${d.getUTCMonth().toString().padStart(2, '0')}${d.getUTCDay().toString().padStart(2, '0')}_${d.getUTCHours().toString().padStart(2, '0')}${d.getUTCMinutes().toString().padStart(2, '0')}${d.getUTCSeconds().toString().padStart(2, '0')}.csv`, "text/csv");
        } finally {
            setIsLoading(false);
        }
    }

    return <Button variant={'contained'} color={'primary'} onClick={downloadLeads} disabled={isLoading} startIcon={<GetApp/>} >{isLoading ? "Loading..." : "Download leads CSV"}</Button>
}


export const LeadsList: React.FC = (props) => {
    return <Box display={'flex'} width={'100%'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
        <DownloadLeads />
    </Box>;
}
