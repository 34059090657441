import React from 'react';
import get from 'lodash/get';
import CountryRegionData from 'country-region-data';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from "@material-ui/icons/Add";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { stringify } from "querystring"
import { UserDeviceSimpleList, UserDeviceDatagrid } from "./userdevices";
import { validatePhone } from "./validation"
import Button from './common/ThemedButton';
import {
    SelectInput,
    FunctionField,
    Link,
    List,
    Show,
    SimpleShowLayout,
    Datagrid,
    BooleanField,
    DateField,
    TextField,
    EmailField,
    ReferenceManyField,
    Edit,
    EditButton,
    SaveButton,
    DeleteWithConfirmButton,
    Toolbar,
    SimpleForm,
    BooleanInput,
    TextInput,
    Create,
    useCreate,
    useNotify,
    useUpdate,
    required,
    minLength,
    maxLength,
    email,
    TitleProps,
    FilterProps,
    Filter,
    SearchInput,
    ArrayInput,
    SimpleFormIterator
} from 'react-admin';
import {NullableTextInput} from "./common";
import {TagsField} from "./common/Tags";
import RefreshTemporaryPasswordButton from "./RefreshTemporaryPasswordButton";
import {useManageSubscription} from "./subscription";
import ThemedEditButton from "./common/ThemedEditButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ThemedBackButton from "./common/ThemedBackButton";


export const UserList: React.FC<React.ComponentProps<typeof List>> = props => (
    <List {...props}
          perPage={25}
          filters={<UserFilter/>}
    >
        <Datagrid rowClick={"show"}>
            <TextField source="givenName" label="First name(s)"/>
            <TextField source="familyName" label="Surname"/>
            <TextField source="phone_number" label="Phone number"/>
            <EmailField source="email"/>
            <DateField source="dateCreated" label="Created" />
            {props.permissions && !props.permissions.isReadOnly && <EditButton/>}
        </Datagrid>
    </List>
);


const SendVerificationEmailButton = React.forwardRef<any, React.ComponentProps<typeof Button>>((props , ref) => {
    const notify = useNotify();
    const [sendVerification, { loading }] = useCreate(
        'verifyemail',
        {},
        {
            undoable: false,
            onSuccess: () => {
                notify(`Verification email sent to ${props.record?.email}`, 'info', {}, false);
            },
            onFailure: (error: Error) => notify(`Failed to send verification message: ${error.message}`, 'warning'),
        }
    );

    if (props.record?.emailVerified !== false) {
        return null;
    }
    return (
        <Button ref={ref} variant={'contained'} label={loading ? "Sending..." : "Resend verification email"} onClick={sendVerification} disabled={loading} />
    )
})


const VerifyEmailNowButton = React.forwardRef<any, React.ComponentProps<typeof Button>>((props , ref) => {
    const notify = useNotify();
    const [markVerified, { loading }] = useUpdate(
        'users',
        props.record?.id,
        { ...props.record, emailVerified: true },
        {
            undoable: false,
            onSuccess: () => {
                notify(`Email ${props.record?.email} marked as verified`, 'info', {}, false);
            },
            onFailure: (error: Error) => notify(`Failed to mark email as verified: ${error.message}`, 'warning'),
        }
    );

    if (props.record?.emailVerified !== false) {
        return null;
    }

    return (
        <Button variant={'contained'} label={loading ? "Marking verified..." : "Mark as verified"} onClick={markVerified} disabled={loading} />
    )
})


const AddNewUserDeviceLinkButton = React.forwardRef<any, React.ComponentProps<typeof Button>>(({record }, ref) => {
    const classes = useStyles();
    if (!record) {
        return null;
    }
    return(
        <Button
            ref={ref}
            className={classes.addLinkButton}
            variant="contained"
            component={Link}
            to={{
                pathname: "/userdevices/create",
                search: '?' + stringify({user_id: record.id, phone_number: record.phone_number}),
            }}
            label="Add a device"
        >
            <AddIcon />
        </Button>
)});

const SendUserSmsLinkButton = React.forwardRef<any, React.ComponentProps<typeof Button>>(({record }, ref) => {
    const classes = useStyles();
    if (!record) {
        return null;
    }
    return(
        <Button
            ref={ref}
            className={classes.addLinkButton}
            variant="contained"
            component={Link}
            to={{
                pathname: "/usersms/create",
                search: '?' + stringify({user_id: record.id}),
            }}
            label="Send an SMS"
        >
            <AddIcon />
        </Button>
)});

const ManageSubscriptionsButton: React.FC = () => {
    const {manageSubscription, isLoadingSubscriptionManagement} = useManageSubscription();
    return <Button
        variant="contained"
        label={isLoadingSubscriptionManagement ? 'Loading' : 'Manage subscriptions'}
        onClick={manageSubscription}
        disabled={isLoadingSubscriptionManagement}
    >
        <NotificationsIcon />
    </Button>
}

export const UserShow: React.FC<React.ComponentProps<typeof Show>> = props => {
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))

    return (
        <Show {...props} title={<UserItemTitle/>} actions={false} >
            <SimpleShowLayout>
                {props.permissions && !props.permissions.isReadOnly && <ThemedEditButton variant={'contained'} label={'Update my details'} />}

                <FunctionField label="Name" render={
                    (record: any) => `${record.givenName} ${record.familyName}`
                } />
                <TextField source="phone_number"/>
                <FunctionField label="Email" render={
                    (record: any) => `${record.email} ${record.emailVerified ? '(verified)' : '(not verified)'}`
                } />
                {props.permissions && props.permissions.isUser ? <SendVerificationEmailButton/> : <VerifyEmailNowButton/>}
                <ReferenceManyField reference="userdevices" target="user_id" source="id" label="My Pippa Devices">
                    {isSmall ? (
                        <UserDeviceSimpleList permissions={props.permissions} />
                    ) : (
                        <UserDeviceDatagrid permissions={props.permissions} />
                    )}

                </ReferenceManyField>
                {props.permissions && !props.permissions.isReadOnly && <AddNewUserDeviceLinkButton />}
                {props.permissions && props.permissions.isUser && <div style={{marginTop: "10px"}}><ManageSubscriptionsButton /></div>}
                {props.permissions && props.permissions.isTenantMember && <DateField source="dateCreated" showTime emptyText={'Unknown'}/>}
                {props.permissions && props.permissions.isTenantMember && <DateField source="dateModified" showTime emptyText={'Unknown'}/>}
                <AddressField source="address"/>
                {props.permissions && !props.permissions.isUser && <TextField source="cohortId" emptyText={'Cohort not specified'}/>}
                <BooleanField source="enableImageUpload" label="Improve Pippa by sharing my cooking data when using the Pippa app" />
                {props.permissions && !props.permissions.isUser && <TagsField source={'tags'} />}
                {props.permissions && !props.permissions.isUser && (
                    <ReferenceManyField reference="usersms" target="user_id" source="id" label="SMS History">
                        <Datagrid>
                            <DateField source="timestamp" />
                            <TextField source="from_number" />
                            <TextField source="to_number" />
                            <TextField source="content" />
                            <BooleanField source="is_incoming" label="From Customer" />
                        </Datagrid>
                    </ReferenceManyField>
                )}
                {props.permissions && !props.permissions.isUser && !props.permissions.isReadOnly && <SendUserSmsLinkButton/>}
                {props.permissions && !props.permissions.isUser && !props.permissions.isReadOnly && <RefreshTemporaryPasswordButton isUser={true}/>}
                <TextField source="id" label="User ID"/>
            </SimpleShowLayout>
        </Show>
    );
}


const AddressField: React.FC<React.ComponentProps<typeof TextField>> = (props) => {
    if (props.source && !get(props.record, props.source)) {
        return <TextField {...props} emptyText={'No address provided'}/>;
    }

    const fields = ['line1', 'line2', 'line3', 'city', 'region', 'postalCode'];

    return (
        <>
            {fields.map((field, i) => {
                const source = props.source + '.' + field;
                if (!get(props.record, source)) {
                    return null;
                }
                return (
                    <React.Fragment key={i}><TextField {...props} source={source} /><br/></React.Fragment>
                )
            })}
            <FunctionField source={"countryIso2"} render={(record: any) => CountryRegionData.find((x) => x.countryShortCode === record.address.countryIso2)?.countryName} />
        </>
    )
}
AddressField.defaultProps = {label: 'Address', addLabel: true}

type UserFormValues = {
    address: {
        line1: string | null;
        line2: string | null;
        line3: string | null;
        region: string | null;
        city: string | null;
        postalCode: string | null;
        countryIso2: string | null;
    }
}
const validateUserForm = (values: UserFormValues) => {
    const {address} = values;

    const errors = {
        address: {
            line1: undefined as (string | undefined),
            postalCode: undefined as (string | undefined),
            countryIso2: undefined as (string | undefined),
        },
    };

    console.log(address)
    if (address && (address.line1 !== null || address.line2 !== null || address.line3 !== null || address.region !== null
    || address.city !== null || address.postalCode !== null || address.countryIso2 !== null)) {
        if (!address.line1) {
            errors.address.line1 = "If an address is provided, it must include a first line"
        }
        if (!address.postalCode) {
            errors.address.postalCode = "If an address is provided, it must include a postal code"
        }
        if (!address.countryIso2) {
            errors.address.countryIso2 = "If an address is provided, it must include a country"
        }
    }
    return errors
};

const CountrySelectChoices = CountryRegionData.map((country) => ({
    id: country.countryShortCode,
    name: country.countryName,
}));

type UserFormProps = Omit<React.ComponentProps<typeof SimpleForm>, 'children'> & {
    isCreate: boolean
}
const UserForm: React.FC<UserFormProps> = ({isCreate, ...props}) => {
    const initialTagsValue = React.useMemo(() => [], []);

    return (
        <SimpleForm {...props} redirect="show" validate={validateUserForm}>
            <TextInput source="givenName" label="First name(s)" validate={[required(), minLength(1), maxLength(50)]}/>
            <TextInput source="familyName" label="Surname" validate={[required(), minLength(1), maxLength(50)]}/>
            <TextInput source="phone_number" validate={[required(), validatePhone as any]}/>
            <TextInput source="email" validate={[required(), email()]}/>
            <BooleanInput source="emailVerified" initialValue={true}
                          disabled={!props.permissions || props.permissions.isUser}/>
            <NullableTextInput source={"address.line1"} label="Address line 1" initialValue={null}/>
            <NullableTextInput source={"address.line2"} label="Address line 2" initialValue={null}/>
            <NullableTextInput source={"address.line3"} label="Address line 3" initialValue={null}/>
            <NullableTextInput source={"address.city"} label="City" initialValue={null}/>
            <NullableTextInput source={"address.region"} label="County/State/Province" initialValue={null}/>
            <NullableTextInput source={"address.postalCode"} label="Postcode / ZIP code" initialValue={null}/>
            <SelectInput source={"address.countryIso2"} label="Country" initialValue={null} resettable
                         choices={CountrySelectChoices} parse={(v: string) => v === "" ? null : v}/>
            <BooleanInput source="enableImageUpload"
                          label="Improve Pippa by sharing my cooking data when using the Pippa app"
                          initialValue={isCreate ? false : undefined}/>
            {props.permissions && props.permissions.isTenantMember && (
                <ArrayInput source="tags" label={"Tags"} initialValue={initialTagsValue}>
                    <SimpleFormIterator>
                        <TextInput source="name" label="Tag Name" validate={[required()]}/>
                        <TextInput source="value" label="Tag Value" validate={[required()]}/>
                    </SimpleFormIterator>
                </ArrayInput>
            )}
            {props.permissions && props.permissions.isTenantMember && (
                isCreate ? (
                    <TextInput source={"cohortId"} label="Cohort" initialValue={null} style={{display: 'none'}}/>
                ) : (
                    <TextField source={"cohortId"} label="Cohort" emptyText={'Cohort not specified'}/>
                )
            )}
        </SimpleForm>
    );
}

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    addLinkButton: {
        marginTop: "1em",
        color: "white",
    },
});
const CustomToolbar: React.FC<React.ComponentProps<typeof Toolbar>> = (props) => {
    return (
        <Toolbar {...props} classes={useStyles()}>
            <SaveButton undoable={props.undoable} style={{'marginRight': '10px'}}/>
            <ThemedBackButton />
            <div style={{flex: 1}} />
            <DeleteWithConfirmButton
                undoable={props.undoable}
                confirmTitle={`Are you sure you want to delete your account?`}
                confirmContent={"This cannot be undone. By deleting your account you will no longer be able to log into the app or the web portal, and you will no longer receive text or phone alerts from Pippa."}
                redirect={'/logout'}
                label={'Delete my account'}
            />
        </Toolbar>
    );
};

export const UserEdit: React.FC<React.ComponentProps<typeof Edit>> = props => {
    const isTenantMember = props.permissions && props.permissions.isTenantMember;
    return (
        <Edit undoable={isTenantMember} {...props} title={<UserItemTitle/>} actions={false}>
            {isTenantMember ? (
                <UserForm isCreate={false} permissions={props.permissions}/>
            ) : (
                <UserForm isCreate={false} toolbar={<CustomToolbar/>} permissions={props.permissions}/>
            )}
        </Edit>
    );
};

export const UserCreate: React.FC<React.ComponentProps<typeof Create>> = props => (
    <Create {...props}>
        <UserForm isCreate={true} permissions={props.permissions}/>
    </Create>
);

const UserItemTitle: React.FC<TitleProps> = ({record}) => (
    <span>User{record ? `: ${record.givenName} ${record.familyName}` : ''}</span>
)

const UserFilter: React.FC<Omit<FilterProps, 'children'>> = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
    </Filter>
);