import React from 'react';
import {
    Create,
    FileInput,
    FileField,
    SimpleForm,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';
import {required} from 'react-admin';
import {RouteComponentProps} from 'react-router';
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {CsvImporter} from "./common/csvImport";


const ImportDevices: React.FC<RouteComponentProps> = ({staticContext, ...props}) => {
    return (
        <CsvImporter resource={'importdevices'}>
            {(onImportStarted) => (
                <Create resource={'importdevices'} basePath={'/importdevices'} title={'Import Devices'} {...props}
                    onSuccess={onImportStarted}>
                    <SimpleForm>
                        <InstructionText />
                        <ReferenceInput label="Tenant" source="tenantId" reference="tenants" validate={[required()]}>
                            <AutocompleteInput optionText="name"
                                               helperText={'All devices in the CSV file will be associated with this Tenant'}/>
                        </ReferenceInput>
                        <FileInput source="files" multiple={false} label={'CSV file'} accept={'.csv,text/csv'}
                                   labelSingle={'custom.drop_csv_file'}>
                            <FileField source="csv1" title="title"/>
                        </FileInput>
                        <CsvSpecification/>
                    </SimpleForm>
                </Create>
            )}
        </CsvImporter>
    )
}

const InstructionText = () => {
    return <Typography>
        While this import should usually work, it is possible that some rows will succeed and some will fail in
        unexpected circumstances. The failed rows will be missing from the results CSV and PDF.
        You can check the log file included in the result for any error messages.
        You'll then need to delete these processed devices from the input CSV and try them again.
        An error "Failed to import device" means the import failed for that device.
        An error "Failed to activate SIM" does not mean the device import failed, just that the SIM could not be activated.
        You'll need to check and activate the SIM manually.
        If the format of the CSV file is incorrect the entire import should fail, then you can correct the file and try again.
    </Typography>
}

const csvSpecRows = [
    ["STM_SW", "Firmware version, in the format XrY, where X is the major version and Y is the minor version", "1r212"],
    ["ALGO_SW", "Algorithm version (matlab release version), in the format X.Y, where X is the major version and Y is the minor version", "0.92"],
    ["CHIP_ID", "96-bit Processor ID, as a hex string. May or may contain trailing Fs to make it 128-bit", "000102030405060708090A0B or 000102030405060708090A0BFFFFFFFF"],
    ["SIM_NUMBER", "15-digit IMSI of the chip sim", "204012345678912"],
    ["HARDWARE", "Hardware version of the Pippa. Options include SAFE_0, LITE_0, LITE_1.", "SAFE_1"]
]

const CsvSpecification = () => {
    return <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <Typography variant="subtitle2">CSV File Specification</Typography>
        </AccordionSummary>
            <AccordionDetails style={{flexDirection: 'column'}}>
            <Typography>The following columns are expected in the CSV file</Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Column Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Example</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {csvSpecRows.map((row, i) => (
                            <TableRow key={i}>
                                <TableCell component="th" scope="row">
                                    {row[0]}
                                </TableCell>
                                <TableCell>{row[1]}</TableCell>
                                <TableCell>{row[2]}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography style={{marginTop: 10}}>{'\u2022'} Extra columns are OK, they will be ignored</Typography>
            <Typography>{'\u2022'} Blank values are not OK, all values are required</Typography>
            <Typography>{'\u2022'} There should be a header row with the column names</Typography>
            <Typography>{'\u2022'} Columns can be in any order, as long as the column names are correct</Typography>
        </AccordionDetails>
    </Accordion>
}

export default ImportDevices;
