import React, {useContext, useEffect} from 'react';
import {Authenticator, AmplifyProvider, Theme, defaultTheme, View, Image} from '@aws-amplify/ui-react';
import {AuthState, CognitoUserInterface, onAuthUIStateChange} from '@aws-amplify/ui-components';
import {ConfigContext} from './App';
import {Hub, HubCallback,} from '@aws-amplify/core';
import '@aws-amplify/ui-react/styles.css'
import './LoginPage.css';
import {useCheckAuth, useLogin} from 'react-admin'
import {useHistory, useLocation} from "react-router-dom";
import useStateSafe from "./common/useStateSafe";


const amplifyTheme: Theme = {
    name: "pippa",
    tokens: {
        colors: {
            brand: {
                primary: defaultTheme.tokens.colors.blue
            },
        }
    }
};


const authenticatorComponents = {
    Header() {
        return (
        <View textAlign="center">
            <Image
              alt="Pippa logo"
              src="/login_pippa_logo.png"
              className="pippaLogo"
            />
          </View>
        )
    }
};

const LoginPage: React.FC = () => {
    useRedirectIfAuthenticated();
    const login = useLogin();
    const config = useContext(ConfigContext);

    useEffect(() => {
        const listener: HubCallback = (data) => {
            if (data.payload.event === 'signIn') {
                login(undefined, undefined)
            }
        }
        Hub.listen('auth', listener)
        return () => Hub.remove('auth', listener);
    }, [login])

    return (
        <div className={'loginPageContainer'}>
            <AmplifyProvider theme={amplifyTheme}>
                <Authenticator
                    loginMechanisms={['email']}
                    signUpAttributes={['given_name', 'family_name', 'phone_number']}
                    components={authenticatorComponents}
                    hideSignUp={!config.IS_USER}
                    formFields={{
                        setupTOTP: {
                            QR: {
                                totpIssuer: 'Pippa Dashboard'
                            }
                        }
                    }}
                >
                    {() => (
                        <div />
                    )}
                </Authenticator>
            </AmplifyProvider>
        </div>
    )
}

const useRedirectIfAuthenticated = () => {
    const checkAuth = useCheckAuth();
    const history = useHistory();
    const location = useLocation();
    // Even though location is not used in this useEffect, we still want to rerun the useEffect whenever location
    // changes, because we want to run checkAuth again. This prevents us getting stuck on /login when we are already
    // logged in.
    useEffect(() => {
        checkAuth({}, false, undefined, true)
            .then(() => {
                // already authenticated, redirect to the home page
                history.push('/');
            })
            .catch(() => {
                // not authenticated, stay on the login page
            });
    }, [checkAuth, history, location]);
}

export const useAuthUIState = () => {
    const {state: authUiState, setState: setAuthUiState} = useStateSafe<{state: AuthState | undefined, data: CognitoUserInterface | undefined}>({state: undefined, data: undefined});

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthUiState({state: nextAuthState, data: authData as (CognitoUserInterface | undefined)});
        });
    }, [setAuthUiState]);

    return authUiState;
}

export default LoginPage;
