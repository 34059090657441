import React from 'react';
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from '@material-ui/core/styles';
import {ParameterProfileLayerDatagrid, ParameterSetDatagrid} from "./parameterProfileLayers";
import {stringify} from "querystring";
import {
    Link,
    Button,
    Show,
    SimpleShowLayout,
    List,
    Datagrid,
    TextField,
    ReferenceField,
    ReferenceArrayField,
    ArrayField,
    Labeled,
    TitleProps,
    Edit,
    SingleFieldList,
    ChipField,
    required,
    maxLength,
} from 'react-admin';
import { Create, TextInput, SimpleForm, ArrayInput, FormDataConsumer, FormDataConsumerRenderParams, SelectInput, ReferenceInput, SimpleFormIterator } from 'react-admin';
import TruncatedTextField from "./common/TruncatedTextField";
import ToolbarWithoutDelete from "./common/ToolbarWithoutDelete";


export const ParameterProfileList: React.FC<React.ComponentProps<typeof List>> = props => (
    <List {...props} title={"Parameter Profiles"} bulkActionButtons={false} pagination={false}>
        <Datagrid rowClick={"show"}>
            <TextField source="name" />
            <TruncatedTextField source="description" maxLength={50}/>
            <ReferenceArrayField label="Layers" reference="parameterprofilelayers" source="layerIds" >
                <SingleFieldList linkType={'show'}>
                    <ChipField source={'name'} />
                </SingleFieldList>
            </ReferenceArrayField>
        </Datagrid>
    </List>
);

export const ParameterProfileShow: React.FC<React.ComponentProps<typeof Show>> = (props) => (
    <Show {...props} title={<ParameterProfileTitle/>}>
        <SimpleShowLayout>
            <TextField source="name"/>
            <TextField source="description" emptyText={'No description provided'} component="pre"/>
            <ReferenceArrayField label="Layers" reference="parameterprofilelayers" source="layerIds">
                <ParameterProfileLayerDatagrid />
            </ReferenceArrayField>
            {props.permissions && props.permissions.isRoot && !props.permissions.isReadOnly && <CreateParameterUpdateButton />}
            <TextField source="id" label="Profile ID"/>
        </SimpleShowLayout>
    </Show>
)


type ParameterProfileFormProps = Omit<React.ComponentProps<typeof SimpleForm>, 'children'> & {
    isCreate: boolean;
}
const ParameterProfileForm: React.FC<ParameterProfileFormProps> = ({isCreate, ...props}) => (
    <SimpleForm {...props}>
       <TextInput source="name" initialValue={''}
                  helperText="Name for this profile"
                  validate={[required(), maxLength(100)]} />
       <TextInput source="description" initialValue={''} parse={(v: string) => v}
                  fullWidth={true} multiline={true}
                  helperText="Describe what this profile is for"
                  validate={[maxLength(1000)]} />
        {isCreate && <ArrayInput source="layerIds" label="Layers" validate={required()}>
           <SimpleFormIterator>
               <ReferenceInput source={''} reference="parameterprofilelayers" label="Layer ID">
                   <SelectInput optionText="name"/>
               </ReferenceInput>
               <FormDataConsumer>
                    {({ getSource, scopedFormData, ...rest }: FormDataConsumerRenderParams) => {
                        return scopedFormData && getSource ? (
                            <ReferenceField source={getSource('').slice(0, -1)} record={rest.formData} link={false} basePath="" reference={"parameterprofilelayers"}>
                                <Labeled label='Description'>
                                    <TextField source="description" component="pre" />
                                </Labeled>
                            </ReferenceField>
                        ) : null;
                    }}
                </FormDataConsumer>
               <FormDataConsumer>
                    {({ getSource, scopedFormData, ...rest }: FormDataConsumerRenderParams) => {
                        return scopedFormData && getSource ? (
                            <ReferenceField source={getSource('').slice(0, -1)} record={rest.formData} link={false} basePath="" reference={"parameterprofilelayers"}>
                                <Labeled label='Parameters'>
                                    <ArrayField source="parameters">
                                        <ParameterSetDatagrid showIndex/>
                                    </ArrayField>
                                </Labeled>
                            </ReferenceField>
                        ) : null;
                    }}
                </FormDataConsumer>
           </SimpleFormIterator>
       </ArrayInput>}
    </SimpleForm>
)


export const ParameterProfileEdit: React.FC<React.ComponentProps<typeof Edit>> = (props) => (
    <Edit {...props} title={<ParameterProfileTitle/>}>
        <ParameterProfileForm isCreate={false} toolbar={<ToolbarWithoutDelete />} />
    </Edit>
)


export const ParameterProfileCreate: React.FC<React.ComponentProps<typeof Create>> = (props) => (
    <Create {...props} title={'New parameter profile'}>
        <ParameterProfileForm isCreate={true} />
    </Create>
);

const useStyles = makeStyles({
    createParameterUpdateButton: {
        marginTop: "1em",
        marginBottom: "1em",
        color: "white",
    },
});
const CreateParameterUpdateButton = React.forwardRef<any, React.ComponentProps<typeof Button>>((props, ref) => {
    const classes = useStyles();
    if (!props.record) {
        return null;
    }
    return(
        <Button
            ref={ref}
            className={classes.createParameterUpdateButton}
            variant="contained"
            component={Link}
            to={{
                pathname: "/parameterupdates/create",
                search: '?' + stringify({profile_id: props.record.id}),
            }}
            label="Assign to device"
        >
            <AddIcon />
        </Button>
)});

const ParameterProfileTitle: React.FC<TitleProps> = ({record}) => (
    <span>Parameter profile {record ? record.id : ''}</span>
)
