import React from "react";
import {Button, HttpError, useDataProvider} from "react-admin";
import SendIcon from "@material-ui/icons/Send";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";


const WebhookTestButton: React.FC<{webhookEventType: string, label: string}> = ({webhookEventType, label}) => {
    const dataProvider = useDataProvider();
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [success, setSuccess] = React.useState(false);

    const onClick = React.useCallback(() => {
        setLoading(true);
        setErrorMessage(null);
        setSuccess(false);
        (dataProvider as any).testWebhook(webhookEventType).then((response: any) => {
            setLoading(false);
            setSuccess(true);
        }).catch((err: HttpError) => {
            setLoading(false);
            if (err.status === 400) {
                setErrorMessage(JSON.stringify(err.body.message));
            }
        })
    }, [dataProvider, webhookEventType])

    return <div style={{marginTop: '10px'}}>
        <Button variant="contained" style={{backgroundColor: success ? '#00ab00' : errorMessage !== null ? '#ab0000' : undefined}} label={'Trigger ' + label} disabled={loading} onClick={onClick}>
            {success ? <CheckIcon/> : errorMessage !== null ? <CloseIcon/> : <SendIcon />}
        </Button>
        {errorMessage && <div>{errorMessage}</div>}
    </div>
}

export default WebhookTestButton