import React from 'react';
import dataProvider from './dataProvider';
import { AuthPermissions, TenantMemberAuthProvider, UserAuthProvider } from './authProvider';
import { DeviceList, DeviceShow, DeviceCreate, DeviceEdit } from './devices'
import { BlankList } from './blankResource'
import RouterIcon from '@material-ui/icons/Router';
import WarningIcon from '@material-ui/icons/Warning';
import DirectionsWalk from '@material-ui/icons/DirectionsWalk';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PersonIcon from '@material-ui/icons/Person';
import Business from '@material-ui/icons/Business';
import Message from '@material-ui/icons/Message';
import SendIcon from '@material-ui/icons/Send'
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import { AlertList, AlertShow } from './alerts'
import { PingShow } from './pings'
import { theme } from './theme'
import { UserList, UserShow, UserEdit, UserCreate } from './users'
import { UserDeviceEdit, UserDeviceCreate } from './userdevices'
import { MemberList, MemberShow, MemberEdit, MemberCreate } from './tenantMembers'
import { TenantList, TenantShow, TenantEdit, TenantCreate } from './tenants'
import { WebhookList, WebhookShow, WebhookCreate } from './webhooks'
import { RawMessageList } from './rawMessages'
import {
    ParameterProfileList,
    ParameterProfileShow,
    ParameterProfileCreate,
    ParameterProfileEdit
} from './parameterProfiles'
import {
    ParameterProfileLayerList,
    ParameterProfileLayerShow,
    ParameterProfileLayerCreate,
    ParameterProfileLayerEdit
} from './parameterProfileLayers'
import { ParameterUpdateCreate, ParameterUpdateShow } from "./parameterUpdates";
import { UserSmsCreate } from "./userSms";
import LoginPage from './LoginPage'
import CustomLayout from './Layout'
import customRoutes from './customRoutes';
import { createBrowserHistory as createHistory } from 'history';
import { WebClientConfig } from "./config";
import UserHome from "./UserHome";
import {FirstUseShow} from "./firstuses";
import {VodafoneSimEdit} from "./vodafoneSims";
import {LeadsList} from "./leads";
import i18nProvider from "./i18nProvider";
import Dashboard from "./Dashboard";

const { Admin, Resource } = require('react-admin');

/* See https://material.io/tools/icons */

interface AppProps {
    config: WebClientConfig
}

export const ConfigContext = React.createContext<WebClientConfig>({
    IS_USER: true,
    API_URL: '',
    FEEDBACK_URL: '',
    USER_POOL_ID: '',
    USER_POOL_WEB_CLIENT_ID: '',
    AWS_REGION: '',
});


const App: React.FC<AppProps> = ({config}) => (
    <ConfigContext.Provider value={config}>
        <Admin
            dashboard={config.IS_USER ? UserHome : Dashboard}
            dataProvider={dataProvider}
            authProvider={config.IS_USER ? UserAuthProvider : TenantMemberAuthProvider}
            i18nProvider={i18nProvider}
            history={createHistory()}
            customRoutes={customRoutes}
            theme={theme}
            loginPage={LoginPage}
            layout={CustomLayout}
            disableTelemetry
        >
            {(permissions: AuthPermissions) => permissions.loggedIn ? [
                <Resource name="devices" list={permissions.isUser ? null : DeviceList} show={DeviceShow} edit={permissions.isRoot && !permissions.isReadOnly ? DeviceEdit : null} create={permissions.isRoot && !permissions.isReadOnly ? DeviceCreate : null} icon={RouterIcon}/>,
                <Resource name="alerts" list={permissions.isUser ? null : AlertList} show={AlertShow} icon={WarningIcon}/>,
                !permissions.canSeePersonalData ? null : <Resource name="users" list={permissions.isUser ? null : UserList} show={UserShow} edit={permissions.isReadOnly ? null : UserEdit} create={permissions.isUser || permissions.isReadOnly ? null : UserCreate} icon={PersonIcon}/>,
                !permissions.canSeePersonalData ? null : permissions.isUser ? null : <Resource name="usersms" create={permissions.isReadOnly? null : UserSmsCreate}/>,
                !permissions.canSeePersonalData ? null : <Resource name="userdevices" edit={permissions.isReadOnly ? null : UserDeviceEdit} create={permissions.isReadOnly ? null : UserDeviceCreate}/>,
                permissions.isUser ? null : <Resource name="members" list={!permissions.canSeePersonalData ? null : MemberList} show={MemberShow} edit={permissions.isReadOnly ? null : MemberEdit} create={permissions.isReadOnly ? null : MemberCreate} icon={SupervisorAccountIcon} options={{label: "Organisation"}}/>,
                <Resource name="cooking"/>,
                <Resource name="qrcode"/>,
                permissions.isUser ? null : <Resource name="vodafonesim" edit={permissions.isRoot && !permissions.isReadOnly ? VodafoneSimEdit : null} />,
                permissions.isUser ? null : <Resource name="pings" show={PingShow}/>,
                permissions.isUser ? null : <Resource name="firstuses" show={FirstUseShow} />,
                <Resource name="parameterupdates" show={ParameterUpdateShow} create={permissions.isRoot && !permissions.isReadOnly ? ParameterUpdateCreate : null}/>,
                permissions.isUser ? null : <Resource name="accesslevels"/>,
                permissions.isUser ? null : <Resource name="hardwareversions"/>,
                permissions.isUser || !permissions.isRoot ? null : <Resource name="rawmessages" list={RawMessageList} icon={Message} options={{label: "Incoming SMS"}}/>,
                permissions.isUser || !permissions.isRoot ? null : <Resource name="tenants" list={TenantList} show={TenantShow} create={permissions.isReadOnly ? null : TenantCreate} edit={permissions.isReadOnly ? null : TenantEdit} icon={Business}/>,
                permissions.isUser || !permissions.isRoot ? null : <Resource name="parameterprofiles" list={ParameterProfileList} show={ParameterProfileShow} create={permissions.isReadOnly ? null : ParameterProfileCreate} edit={permissions.isReadOnly ? null : ParameterProfileEdit} icon={SettingsInputAntennaIcon} options={{label: "Parameter Profiles"}}/>,
                permissions.isUser || !permissions.isRoot ? null : <Resource name="parameterprofilelayers" list={ParameterProfileLayerList} show={ParameterProfileLayerShow} create={permissions.isReadOnly ? null : ParameterProfileLayerCreate} edit={permissions.isReadOnly ? null : ParameterProfileLayerEdit} icon={SettingsInputAntennaIcon} options={{label: "Parameter Layers"}}/>,
                permissions.isUser || !permissions.isRoot ? null : <Resource name="parameters" />,
                permissions.isRoot && permissions.canSeePersonalData ? <Resource name="exportleads" list={LeadsList} icon={DirectionsWalk} options={{label: 'Export Leads'}}/> : null,
                <Resource name="changepassword" />,
                <Resource name="importdevices" />,
                !permissions.isAdmin ? null : <Resource name="webhooks" list={WebhookList} create={permissions.isReadOnly ? null : WebhookCreate} show={WebhookShow} icon={SendIcon} />,
                !permissions.isAdmin ? null : <Resource name="webhookeventtypes"/>,
                !permissions.isAdmin ? null : <Resource name="webhookapiversions"/>,
            ] : [<Resource name="blank" list={BlankList} />]}
        </Admin>
    </ConfigContext.Provider>
);

export default App;
