import { stringify } from 'querystring';
import { getAccessToken, changePassword } from './authProvider';
import { getConfigAsync } from "./config";
import { fetchUtils, HttpError } from 'react-admin';
import runImportDevices from "./runImportDevices";

const NON_PAGINATED_RESOURCES = ["parameterprofiles", "parameterprofilelayers", "parameters", "userdevices", "parameterupdates", "usersms", "webhooks"]

interface FetchJsonResponse {
    headers: any
    json: any
}

type IdType = string;

interface PaginationParams {
    page: number
    perPage: number
}

interface SortParams {
    field: string
    order: string
}

interface FilterParams {
    [propName: string]: string
}

interface Record {
    id: IdType
    [propName: string]: any
}
interface GetListParams {
    pagination: PaginationParams
    sort: SortParams
    filter: FilterParams
}
interface GetListResponse {
    data: Record[]
    total: number
}

interface GetOneParams {
    id: IdType
}
interface GetOneResponse {
    data: Record
}

interface CreateParams {
    data: {
        [propName: string]: any
    }
}
interface CreateResponse {
    data: Record
}

interface UpdateParams {
    id: IdType
    data: object
    previousData: object
}
interface UpdateResponse {
    data: Record
}

interface UpdateManyParams {
    ids: IdType[]
    data: object
}
interface UpdateManyResponse {
    data: IdType[]
}

interface DeleteParams {
    id: IdType
    previousData: object
}
interface DeleteResponse {
    data: Record
}

interface DeleteManyParams {
    ids: IdType[]
}

interface DeleteManyResponse {
    data: IdType[]
}

interface GetManyParams {
    ids: IdType[]
}
interface GetManyResponse {
    data: Record[]
}

interface GetManyReferenceParams extends GetListParams {
    target: string
    id: string
}
type GetManyReferenceResponse = GetListResponse

interface RequestOptions {
    headers: Headers,
    method: string,
    body? : any
}

const DATE_PICKER_FORMAT = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2})?$/;

const dataProvider = {
    getList: getList,
    getOne: getOne,
    getMany: getMany,
    getManyReference: getManyReference,
    update: update,
    updateMany: updateMany,
    create: create,
    delete: deleteOne,
    deleteMany: deleteMany,
    tenantId: null,
    getBecomeTenant: getBecomeTenant,
    getOneNoTenantSimulation: getOneNoTenantSimulation,
    getWithQuery: getWithQuery,
    getWithoutQuery: getWithoutQuery,
    refreshTemporaryPassword: refreshTemporaryPassword,
    refreshAlertFeedbackToken: refreshAlertFeedbackToken,
    testWebhook,
    createBillingCheckoutSession,
    createBillingPortalSession,
    redeemSubscriptionCode,
    post: post,
};
export default dataProvider;

function getBecomeTenant() {
    if (dataProvider.tenantId !== null && dataProvider.tenantId !== undefined && dataProvider.tenantId !== "") {
        return dataProvider.tenantId
    } else {
        return null;
    }
}

async function getWithQuery(resource: string, queryParams: any) {
    const result = await makeRequest(`${resource}?${stringify(queryParams)}`, 'GET', null);
    return {data: result.json};
}

async function getWithoutQuery(resource: string) {
    const result = await makeRequest(`${resource}`, 'GET', null);
    return {data: result.json};
}

async function post(path: string, data: unknown) {
    const result = await makeRequest(path, 'POST', data);
    return {data: result.json};
}

const makeRequest = async function (url: string, method: string, data: any, includeTenantId? :boolean) {
    const config = await getConfigAsync();
    const { accessToken } = await getAccessToken();

    const isSimulatedAccess = includeTenantId === false ? false : (getBecomeTenant() !== null && !url.endsWith("/tenants"));

    const options: RequestOptions = {
        headers: new Headers({
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}${isSimulatedAccess ? `@${getBecomeTenant()}` : ""}`,
        }),
        method: method,
    };
    if (data !== null) {
        options.body = JSON.stringify(data);
    }
    return await fetchUtils.fetchJson(`${config.API_URL}/${url}`, options);
};

async function makeGetRequest(url: string): Promise<FetchJsonResponse> {
    return await makeRequest(url, 'GET', null);
}

async function makePutRequest(url: string, data: any): Promise<FetchJsonResponse> {
    return await makeRequest(url, 'PUT', data);
}

export async function makePostRequest(url: string, data: any): Promise<FetchJsonResponse> {
    return await makeRequest(url, 'POST', data);
}
async function makeDeleteRequest(url: string): Promise<FetchJsonResponse> {
    return makeRequest(url, 'DELETE', null);
}

function convertDates(filters: any) {
    for (let key in filters) {
        if (filters.hasOwnProperty(key) && DATE_PICKER_FORMAT.exec(filters[key])) {
            filters[key] = new Date(filters[key]).getTime()
        }
    }
}

async function getList(resource: string, params: GetListParams): Promise<GetListResponse> {
    return await makeListRequestWithPaginationDecision(resource, params);
}

async function makeListRequestWithPaginationDecision(resource: string, params: GetListParams): Promise<{data: Record[], total: number}> {
    if (NON_PAGINATED_RESOURCES.includes(resource)) {
        const {json} = await makeGetRequest(`${resource}?${stringify(params.filter)}`);
        return {
            data: json,
            total: json.length,
        }
    } else {
        const response = await cachedMakeRequest(resource, params);
        return {
            data: response.data,
            total: response.totalItems,
        }
    }
}

async function getOneNoTenantSimulation(resource: string, params: GetOneParams): Promise<GetOneResponse> {
    const result = await makeRequest(`${resource}/${params.id}`, 'GET', null, false);
    return {
        data: result.json
    };
}

async function getOne(resource: string, params: GetOneParams): Promise<GetOneResponse> {
    const result = await makeGetRequest(`${resource}/${params.id}`);
    return {
        data: result.json
    };
}

async function create(resource: string, params: CreateParams): Promise<CreateResponse> {
    console.log("CREATE");
    console.log(params);

    if (resource === 'changepassword') {
        try {
            await changePassword(params.data.oldPassword, params.data.newPassword);
        } catch (err) {
            throw new HttpError((err as Error).message, 400);
        }
        return {data: {id: '0'}};
    }

    if (resource === 'importdevices') {
        try {
            const importResponse = await runImportDevices(params.data.tenantId, params.data.files.csv1, (d) => makePostRequest(resource, d));
            return {data: {id: '0', payload: importResponse}};
        } catch (err) {
            throw new HttpError((err as Error).message, 400);
        }
    }

    const postData = cleanUploadData(resource, params.data);

    const result = await makePostRequest(`${resource}`, postData);
    return {
        data: result.json
    };
}

async function createBillingPortalSession(returnUrl: string): Promise<any> {
    const route = 'billing/portalsession'
    const response = await makePostRequest(route, {
        returnUrl: returnUrl
    })
    return {
        data: response.json
    }
}
async function createBillingCheckoutSession(isSetup: boolean, deviceId: string, successUrl: string, cancelUrl: string): Promise<any> {
    const route = 'billing/checkoutsession'
    const response = await makePostRequest(route, {
        isSetup, deviceId, successUrl, cancelUrl
    })
    return {
        data: response.json
    }
}

async function redeemSubscriptionCode({deviceId}: {deviceId: string}, email: string, code: string): Promise<any> {
    // For some weird reason, when deviceId is just passed as a string, code always comes in undefined.
    // When making {deviceId: string} as an object, code then comes through correctly.
    const route = `subscriptions/${deviceId}`;
    const response = await makePostRequest(route, {
        email: email, code: code
    });
    return {
        data: response.json,
    };
}

async function refreshTemporaryPassword(id: string, isUser: boolean): Promise<any> {
    const route = `${isUser ? 'users' : 'members'}/${id}/refreshpassword`;
    const response = await makePostRequest(route, {});
    return {
        data: response.json
    }
}

async function refreshAlertFeedbackToken(alertId: string): Promise<any> {
    const response = await makePostRequest(`alertfeedbacktoken/${alertId}`, {})
    return {
        data: response.json
    }
}

async function testWebhook(webhookEventType: string): Promise<any> {
    const response = await makePostRequest(`webhooks/test`, {
        eventType: webhookEventType
    });
    return {
        data: response.json
    }
}

async function update(resource: string, params: UpdateParams): Promise<UpdateResponse> {
    console.log("UPDATE");
    console.log(params);

    const putData = cleanUploadData(resource, params.data);
    const result = await makePutRequest(`${resource}/${params.id}`, putData);
    return {
        data: result.json
    };
}

function cleanUploadData(resource: string, data: any): any {
    // For users resource if all address fields are null we need to post the address as null
    if (resource === 'users') {
        if (data.address === undefined) {
            return {...data, address: null};
        }
        if (data.address !== null) {
            if (Object.keys(data.address).every((key) => data.address[key] === null)) {
                return {...data, address: null};
            }
        }
    }

    // When posting a parameter set, make sure index is an integer not a string or the API rejects it
    if (resource === "parameterprofilelayers") {
        return {...data, parameters: data.parameters.map((p: any) => ({...p, index: parseInt(p.index)}))}
    }

    return data;
}

async function updateMany(resource: string, params: UpdateManyParams): Promise<UpdateManyResponse> {
    console.log("NOT IMPLEMENTED!");
    return {
        data: ["0"]
    };
}

async function deleteOne(resource: string, params: DeleteParams): Promise<DeleteResponse> {
    await makeDeleteRequest(`${resource}/${params.id}`);
    return {
        data: {
            id: params.id
        }
    };
}

async function deleteMany(resource: string, params: DeleteManyParams): Promise<DeleteManyResponse> {
    await Promise.all(params.ids.map(id => makeDeleteRequest(`${resource}/${id}`)));
    return {
        data: params.ids
    };
}

async function getMany(resource: string, params: GetManyParams): Promise<GetManyResponse> {
    const results = await Promise.all(params.ids.map(id => makeGetRequest(`${resource}/${id}`)));
    return {
        data: results.map(r => r.json)
    }
}

async function getManyReference(resource: string, params: GetManyReferenceParams): Promise<GetManyReferenceResponse> {
    if (params.target === 'device_id') {
        params.filter.device_id = params.id;
    }
    if (params.target === 'user_id') {
        params.filter.user_id = params.id;
    }

    return makeListRequestWithPaginationDecision(resource, params)
}

async function cachedMakeRequest(resource: string, params: GetListParams): Promise<{data: Record[], totalItems: number}> {
    const queryForCache = {
        sortField: params.sort.field,
        sortOrder: params.sort.order,
        ...params.filter
    };
    convertDates(queryForCache);

    const makeCacheKey = (offset: number) => JSON.stringify({
        ...queryForCache,
        resource,
        offset,
        becomeTenant: getBecomeTenant(),
    });

    const { page, perPage } = params.pagination;
    const rangeStart =  (page - 1) * perPage;
    const rangeEnd = (page * perPage) - 1;

    let lastEvaluatedKey = undefined;
    let additionalItemsRequired = 0;

    for (let i = rangeStart; i > 0; i--) {
        lastEvaluatedKey = lastKeyCache.get(makeCacheKey(rangeStart));
        if (lastEvaluatedKey !== undefined) {
            break;
        }
        additionalItemsRequired += 1;
    }
    if (lastEvaluatedKey === undefined) {
        lastEvaluatedKey = null;
    }

    const url = `${resource}?${stringify({
        lastKey: lastEvaluatedKey,
        limit: perPage + additionalItemsRequired,
        ...queryForCache
    })}`;
    const {json} = await makeGetRequest(url);
    if (!json.hasOwnProperty("data")) {
        console.error("data property missing from API response");
        return {
            data: [],
            totalItems: 0,
        };
    }
    const data = json.data.slice(additionalItemsRequired);

    // Update cache with new data
    let totalItems = rangeStart + data.length;
    if (json.hasOwnProperty("lastKey") && json.lastKey !== null) {
        lastKeyCache.set(makeCacheKey(rangeEnd + 1), json.lastKey);
        totalItems += 1;
    }

    return {
        data: data,
        totalItems: totalItems
    };
}

/* This will be a lookup from route+offset => lastEvaluatedKey */
const lastKeyCache: Map<string, string> = new Map();
