import React from 'react'
import {
    List,
    Datagrid,
    TextField,
    Show,
    SimpleShowLayout,
    SelectInput,
    ReferenceInput,
    regex, usePermissions, ListProps, useRedirect, TopToolbar, Button, CreateButton, ExportButton,
} from 'react-admin';
import { RouteComponentProps } from 'react-router';
import { Create, required } from 'react-admin';
import { SimpleForm, TextInput } from 'react-admin';
import { DeleteWithConfirmButton } from 'react-admin';
import {validateUrl} from "./validation";
import {NullableTextInput} from "./common";
import WebhookTestButton from "./WebhookTestButton";
import {AuthPermissions} from "./authProvider";
import SendIcon from "@material-ui/icons/Send";

export const WebhookCreate: React.FC<React.ComponentProps<typeof Create>> = props => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <ReferenceInput label="Event type" source="eventType" reference={"webhookeventtypes"} validate={required()} defaultValue={"alert_created"}>
                <SelectInput optionText={"id"}/>
            </ReferenceInput>
            <ReferenceInput label="API version" source="apiVersion" reference={"webhookapiversions"} validate={required()} defaultValue={"1.0"}>
                <SelectInput optionText={"id"}/>
            </ReferenceInput>
            <TextInput source="url" validate={[required(), validateUrl]}/>
            <NullableTextInput source="apiKey" validate={[regex(/^[-a-zA-Z0-9_ :;.,/"'?!(){}[@<>=+*#$&`|~^%\]\\]+$/, "Can only contain numbers, letters, and _ :;.,/\"'?!(){}[@<>=+*#$&`|~^%]\\")]} label="x-api-key header (optional)" initialValue={null}/>
        </SimpleForm>
    </Create>
);

export const WebhookList: React.FC<React.ComponentProps<typeof List>> = props => (
    <List
        {...props}
        actions={<WebhookListActions {...props}/>}
        bulkActionButtons={false}
        perPage={25}
    >
        <Datagrid rowClick={"show"}>
            <TextField source="eventType"/>
            <TextField source="apiVersion"/>
            <TextField source="url"/>
            <TextField source="secret"/>
            <TextField source="apiKey" emptyText={"Not set"} />
            {props.permissions && !props.permissions.isReadOnly && <DeleteWithConfirmButton />}
        </Datagrid>
    </List>
);


export const WebhookTest: React.FC<RouteComponentProps> = (props) => {
    const {permissions}: {permissions?: AuthPermissions} = usePermissions();
    if (!permissions || !permissions.loggedIn || permissions.isReadOnly || !permissions.isAdmin) {
        return null;
    }
    return (
        <div style={{backgroundColor: 'white', padding: '10px'}}>
            <div>Click the buttons below to simulate that type of event occurring. If any webhooks are registered for that event type, they will be triggered with some fake data.</div>
            <div>Success or failure indications after you click only show whether the event was successfully triggered. It is not an indication of whether your webhook endpoints are working or not.</div>
            <WebhookTestButton webhookEventType={'alert_created'} label={'Alert'} />
            <WebhookTestButton webhookEventType={'alert_cleared'} label={'Alert Cleared'} />
            <WebhookTestButton webhookEventType={'battery_replaced'} label={'Battery Replaced'} />
            <WebhookTestButton webhookEventType={'low_battery'} label={'Low Battery'} />
        </div>
    );
}

export const WebhookShow: React.FC<React.ComponentProps<typeof Show>> = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="eventType"/>
            <TextField source="apiVersion"/>
            <TextField source="url"/>
            <TextField source="secret"/>
            <TextField source="apiKey" emptyText={"Not set"} />
            <TextField source="id"/>
            {props.permissions && !props.permissions.isReadOnly && props.permissions.isAdmin && <DeleteWithConfirmButton />}
        </SimpleShowLayout>
    </Show>
);


const WebhookListActions: React.FC<ListProps> = (props) => {
    const redirect = useRedirect();

    const permissions: AuthPermissions | undefined = props.permissions;

    return (
        <TopToolbar>
            {permissions && permissions.loggedIn && !permissions.isReadOnly && permissions.isRoot && (
                <Button
                    onClick={() => redirect('/webhooktest')}
                    label="Test"
                >
                    <SendIcon/>
                </Button>
            )}
            {permissions && permissions.loggedIn && !permissions.isReadOnly && permissions.isAdmin && (
                <CreateButton/>
            )}
            <ExportButton/>
        </TopToolbar>
    );
}
