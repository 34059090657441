import React from 'react';
import {Bar} from "react-chartjs-2";
import {InjectedFieldProps} from 'ra-ui-materialui/src/field/types';

const colors = ['blue', 'red', 'green', 'orange', 'magenta'];

interface Props extends InjectedFieldProps {
    binEdgeSource: string;
    binValueSource: string;
}

const CookDistHistField: React.FC<Props> = ({ binEdgeSource, binValueSource, record }) => {
    if (!record) {
        return null;
    }
    return (
    <Bar data={{
        labels: record[binEdgeSource],
        datasets: (record[binValueSource] as {label: string, histValues: number[][]}[]).map((x, i) => {return {
            label: x.label,
            data: x.histValues,
            backgroundColor: colors[i]
        }})
    }}
         options={{
            scales: {
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: 'Hour of day'
                    },
                    stacked: true
                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: 'Number of events'
                    },
                    stacked: true,
                },
            }
        }}
    />
)};

export default CookDistHistField;
