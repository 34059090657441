import React from 'react';
import "./Dashboard.css";
import get from 'lodash/get';
import {AuthPermissions} from "./authProvider";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs';
import {
    ArrayField,
    Datagrid, DateField, FunctionField, Link,
    List,
    ListProps,
    Record,
    SingleFieldList,
    Title, useListContext,
    usePermissions, useRecordContext
} from 'react-admin';

interface DashboardProps {
    permissions: AuthPermissions
}

const Dashboard: React.FC<DashboardProps> = props => {
    const [value, setValue] = React.useState("0");

     const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue);
      };

    return <Card>
        <Title title="Pippa Admin Dashboard Home" />
        <Tabs
          value={value}
          onChange={handleChange}
        >
        <Tab label={"Cooking fire risk"} value={"0"}/>
        <Tab label={'Slow response risk'} value={"1"}/>
    <Tab label={'Latest alerts'} value={"2"}/>
            </Tabs>

        {value === "0" ? (
            <CardContent>
                <DeviceRiskList {...props}
                    sortField={"risk_factors.alerts_per_year_percentile"}
                    riskValueFieldName="totalAlerts"
                    riskValueLabel="Total alerts"
                    riskPercentileFieldName="alertsPerYearPercentile"/>
            </CardContent>
            ) : value === "1" ? (
        <CardContent>
                <DeviceRiskList {...props}
                                sortField={"risk_factors.response_time_percentile"}
                                riskValueFieldName="riskFactors.meanAlertResponseTimeSeconds"
                                riskValueLabel="Average response (s)"
                                riskPercentileFieldName="responseTimePercentile"
                />
        </CardContent>
            ) : value === "2" ? (
        <CardContent>
            <LastAlertsList {...props} />
        </CardContent>
        ): null}
    </Card>
};

export default Dashboard


const SingleFieldListWithEmpty: React.FC = (props) => {
    const listContext = useListContext();
    const record = useRecordContext();
    if (listContext.ids.length === 0) {
        return <Link to={`/devices/${record.id}/show`}>
            <div className={"nameAddressValue"}>{record.id.toString().slice(0, 7)} (no user)</div>
        </Link>
    }
    return (
        <SingleFieldList {...props} linkType={false}>
            <NameAddressField />
        </SingleFieldList>
    )
}


const UserField: React.FC = (props) => {
    const { permissions } : { permissions?: AuthPermissions } = usePermissions();

    return permissions && permissions.loggedIn && permissions.canSeePersonalData ? (
        <ArrayField {...props} label={"User"} source={"users"} fieldKey={"id"}>
            <SingleFieldListWithEmpty />
        </ArrayField>
        ) : (
            <FunctionField {...props} label={"Device ID"} render={(record?: Record) => record && record.id.toString().slice(0, 7)} />
        )
}


type DeviceRiskListProps = ListProps & {
    sortField: string
    riskPercentileFieldName: string
    riskValueFieldName: string
    riskValueLabel: string
}
const DeviceRiskList: React.FC<DeviceRiskListProps> = props => {
    const { permissions } : { permissions?: AuthPermissions } = usePermissions();

    return (
        <List {...props} basePath={'/devices'} resource={'devices'} filter={{join_users: 'true', hasFirstUse: 'true'}} bulkActionButtons={false} actions={false}
        perPage={100} pagination={false}
        sort={{ field: props.sortField, order: 'DESC' }}
        >
            <Datagrid rowClick={permissions && permissions.loggedIn && permissions.canSeePersonalData ? undefined : "show"}>
                <UserField />
                <FunctionField label={props.riskValueLabel} render={(record?: Record) => {
                    if (!record) {
                        return null
                    }
                    const value = get(record, props.riskValueFieldName);
                    if (!(typeof value === 'number')) {
                        return null;
                    }
                    return Math.round(value);
                }}/>
                <FunctionField label={"Risk percentile"} render={(record?: Record) => {
                    if (!record) {
                        return null;
                    }
                    const percentile = record.riskFactors[props.riskPercentileFieldName];
                    return (
                        <Link to={`/devices/${record.id}/show`}>
                            <div className={"colouredValue " + (!percentile ? "riskNoData" : percentile < 50 ? "lowRisk" : percentile < 75 ? "mediumRisk" : "highRisk")}>
                                {percentile === null ? "Not enough data" : percentile.toFixed(0)}
                            </div>
                        </Link>
                    )
                }} />
            </Datagrid>
        </List>
    )
};


const NameAddressField: React.FC = (props) => {
    return (

        <FunctionField {...props} render={(record?: Record) => {
            if (!record) {
                return null;
            }
            return (
                <Link to={`/users/${record.id}/show`}>
                    <div className={"nameAddressValue"}>
                        <div>
                            {record.givenName + ' ' + record.familyName}
                        </div>
                        {record.addressLine1 && (<div style={{color: 'black'}}>
                            {record.addressLine1 + ', ' + record.addressPostalCode}
                        </div>)}
                    </div>
                </Link>
            )
        }} />
    )
}

const LastAlertsList: React.FC<ListProps> = props => (
    <List {...props} basePath={'/alerts'} resource={'alerts'} filter={{join_users: 'true'}} bulkActionButtons={false} actions={false}
          pagination={false}
          perPage={100}
          sort={{ field: 'timestamp', order: 'DESC' }}
    >
        <Datagrid>
            <UserField />
            <DateField label="Time" source="timestamp" showTime sortable={false}/>
            <FunctionField label={"Type"} sortable={false} render={(record?: Record) => {
                    if (!record) {
                        return null;
                    }
                    const category = record.category;
                    return (
                        <Link to={`/alerts/${record.id}/show`}>
                            <div className={"alertType colouredValue " + (category === "HOB_LEFT_ON" ? "alertLeftOn" : category === "PRE_FIRE" ? "alertPreFire" : category === "PAN_BOILED_DRY" ? "alertBoiledDry" : category === "FIRE" ? "alertFire" : "")}>
                                {category === "HOB_LEFT_ON" ? "Left on" : category === "PRE_FIRE" ? "Dangerously hot" : category === "PAN_BOILED_DRY" ? "Boiled dry" : "Fire"}
                            </div>
                        </Link>
                    )
                }} />
        </Datagrid>
    </List>
);