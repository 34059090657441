import React from 'react';
import { Scatter } from "react-chartjs-2";
import 'chartjs-adapter-moment';
import {InjectedFieldProps} from "ra-ui-materialui/src/field/types";

interface Props extends InjectedFieldProps {
    xLabel: string;
    yLabel: string;
    xSource: string;
    ySource: string;
}

const LineGraphField:  React.FC<Props> = ({ xLabel, yLabel, xSource, ySource, record }) => {
    if (!record) {
        return null;
    }
    return (
        <Scatter
            data={{
                //            labels: record[xSource].map(x => new Date(x).toLocaleTimeString()),
                datasets: [{
                    label: yLabel,
                    data: (record[xSource] as number[]).map((xValue, i) => {
                        return {x: new Date(xValue), y: record[ySource][i]};
                    }),
                    borderColor: 'blue',
                    showLine: true,
                }]
            }}
            options={{
                scales: {
                    x: {
                        type: 'time',
                        display: true,
                        title: {
                            display: true,
                            text: xLabel,
                        },
                        time: {
                            unit: 'minute',
                            displayFormats: {
                                minute: 'HH:mm'
                            },
                        }
                    },
                    y: {
                        display: true,
                        title: {
                            display: true,
                            text: yLabel,
                        }
                    },
                }
            }}
        />
    );
}

export default LineGraphField;
