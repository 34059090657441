import React from 'react';
import PrintIcon from '@material-ui/icons/Print';
import LabelIcon from '@material-ui/icons/Label';
import WarningIcon from '@material-ui/icons/Warning';
import downloadQrPdf from "./common/downloadQrPdf";
import AddIcon from "@material-ui/icons/Add";
import PublishIcon from "@material-ui/icons/Publish";
import { makeStyles } from '@material-ui/core/styles';
import CookDistHistField from './CookDistHistField'
import {ParameterUpdateDatagrid} from "./parameterUpdates";
import {stringify} from "querystring";
import jsonExport from 'jsonexport/dist';
import {
    downloadCSV,
    BooleanInput,
    useRedirect,
    TopToolbar,
    CreateButton,
    ExportButton,
    Link,
    Button,
    List,
    Datagrid,
    TextField,
    DateField,
    BooleanField,
    ReferenceManyField,
    ReferenceField,
    ChipField,
    SingleFieldList,
    BulkExportButton,
    Show,
    NumberField,
    FunctionField,
    TabbedShowLayout,
    TabbedShowLayoutTabs,
    Tab,
    useDataProvider,
    useQuery,
    LinearProgress,
    Error,
    Filter,
    SearchInput,
    TextInput,
    Edit,
    Create,
    SimpleForm,
    minLength,
    maxLength,
    required,
    minValue,
    maxValue,
    number,
    regex,
    ShowProps,
    ListProps,
    FilterProps,
    usePermissions,
    AutocompleteInput,
    SelectInput,
    ReferenceInput,
    ArrayInput,
    SimpleFormIterator,
    Labeled,
    ArrayField,
    useRecordContext, useNotify, useRefresh,
} from 'react-admin';
import { PrevNextPagination } from './prevNextPagination';
import {PublicFieldProps, InjectedFieldProps} from "ra-ui-materialui/lib/field/types";
import {AuthPermissions} from "./authProvider";
import ToolbarWithoutDelete from "./common/ToolbarWithoutDelete";
import {TagsField} from "./common/Tags";
import {NullableTextInput, UnreachableCaseError} from "./common";
import {Exporter} from "react-admin";
import {Record} from "ra-core";
import {SubscriptionStatusField} from "./subscription";
import {useHistory} from "react-router-dom";

export const AdminOnlyShow: React.FC<InjectedFieldProps> = (props) => {
    const { permissions } : {permissions?: AuthPermissions} = usePermissions();

    return (
        <>
            {permissions && permissions.loggedIn && permissions.canSeePersonalData && (
                <Labeled label={"Linked users"}>
                    <ReferenceManyField
                        {...props}
                        reference="users"
                        target="device_id"
                        source="id"
                    >
                        <Datagrid rowClick={"show"}>
                            <TextField source="givenName" sortable={false}/>
                            <TextField source="familyName" sortable={false}/>
                            <TextField source="phone_number" sortable={false}/>
                            <TextField source="email" sortable={false}/>
                        </Datagrid>
                    </ReferenceManyField>
                </Labeled>
            )}
        </>
    );
}

const PrintQrButton: React.FC<{deviceIds: string[]}> = (props) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const dataProvider = useDataProvider();
    const deviceIds = props.deviceIds;
    const downloadPdf = async () => {
        setIsLoading(true);
        try {
            const result = await dataProvider.getWithQuery("qrcode", {device_ids: deviceIds.join(","), format: "pdf"});
            await downloadQrPdf((result as any).data.b64, `qrcode-${deviceIds[0]}.pdf`);
        } finally {
            setIsLoading(false);
        }
    }

    return <Button onClick={downloadPdf} disabled={isLoading} label={isLoading ? "Loading..." : "Print QR code"}><PrintIcon/></Button>
}

const HexField: React.FC<React.ComponentProps<typeof TextField>> = ({source, record, ...rest}) => {
    if (!record || !source) {
        return null;
    }
    const modifiedProps = {source, record: {...record, [source]: '0x' + record[source]}, ...rest}
    return <Labeled label={rest.label}><TextField {...modifiedProps} /></Labeled>
}

interface QrCodeFieldProps extends PublicFieldProps, InjectedFieldProps {}
const QrCodeField: React.FC<QrCodeFieldProps> = (props) => {
    const deviceId = (props.record && props.source) ? props.record[props.source] : null;
    const {data, loading, error} = useQuery({
        type: 'getWithQuery',
        resource: 'qrcode',
        payload: {device_ids: deviceId, format: 'png'}
    });

    if (deviceId === null || loading) return <LinearProgress />;
    if (error) return <Error error={error} />;
    if (!data) return null;

    return (
        <>
            <img src={`data:image/png;base64,${data['b64']}`} title={"QR code"} alt={"QR code"} width="100px"/>
            <PrintQrButton deviceIds={[deviceId]} />
        </>
    )
}

// TabbedShowLayoutTabs currently has a typescript type of React.FC meaning that it has no attributes.
const TabbedShowLayoutTabsUntyped = TabbedShowLayoutTabs as any;

export const DeviceShow: React.FC<ShowProps> = props => {
    const is_tenant_member = props.permissions && props.permissions.isTenantMember
    const is_user = props.permissions && props.permissions.isUser
    const is_admin = props.permissions && props.permissions.isAdmin
    const is_read_only = props.permissions && props.permissions.isReadOnly

    return (<Show
        {...props}
    >
        <TabbedShowLayout tabs={<TabbedShowLayoutTabsUntyped variant={'scrollable'} scrollButtons={'auto'} style={{maxWidth: '100vw'}} {...props} />}>
            <Tab label="Summary">
                {is_user && <SubscriptionStatusField />}
                {is_tenant_member && <DateField source="lastUpdate" />}
                <FunctionField label={"Battery Level"} render={(record: any) => `${record.lastBatteryLevelPercent === null ? 'unknown' : record.lastBatteryLevelPercent.toFixed(0)}%`} />
                {is_tenant_member && <NumberField source="totalAlerts" />}
                {is_tenant_member && <QrCodeField source="id" />}
                {is_tenant_member && <TextField source="identifier" />}
                {is_tenant_member && (
                    <ReferenceField source="identifier" reference="vodafonesim" label="Sim enabled">
                        <BooleanField source="isLive" />
                    </ReferenceField>
                )}
                {is_tenant_member && <HexField source="processorId" label="Processor ID"/>}
                {is_tenant_member && <TextField source="hardwareVersion" />}
                {is_tenant_member && <DateField source="dateCreated" showTime />}
                {is_tenant_member && <DateField source="dateModified" showTime />}
                {is_tenant_member && (
                    <ArrayField source={"stateHistory"} label={"State history (most recent first)"}>
                        <Datagrid>
                            <DateField source={"timestamp"} showTime sortable={false}/>
                            <TextField source={"state"} sortable={false}/>
                        </Datagrid>
                    </ArrayField>
                )}
                {is_admin && !is_read_only && <TransitionDeviceStateButton />}
                {is_tenant_member && (
                    <ArrayField source={"nuisanceStateHistory"} label={"Nuisance state history (most recent first)"}>
                        <Datagrid>
                            <DateField source={"timestamp"} showTime sortable={false}/>
                            <TextField source={"state"} sortable={false}/>
                        </Datagrid>
                    </ArrayField>
                )}
                {is_admin && !is_read_only && <TransitionNuisanceStateButton />}
                {is_tenant_member && <TextField source="comments" emptyText={"No comments"} />}
                {is_tenant_member && <TagsField source={'tags'} />}
                {is_tenant_member && <AdminOnlyShow/>}
            </Tab>
            <Tab label="Alerts" path="alerts">

                <ReferenceManyField
                    reference="alerts" target="device_id" source="id" addLabel={false}
                    pagination={<PrevNextPagination/>}
                    sort={{ field: 'timestamp', order: 'DESC' }}
                >
                    <Datagrid rowClick={"show"}>
                        <DateField source="timestamp" showTime/>
                        <TextField source="category" sortable={false}/>
                    </Datagrid>
                </ReferenceManyField>

            </Tab>
            {is_tenant_member && (<Tab label="Cooking" path="cooking">
                <ReferenceManyField
                    reference="cooking" target="device_id" source="id" addLabel={false}
                    pagination={<PrevNextPagination/>}
                    sort={{ field: 'timestamp', order: 'DESC' }}
                >
                    <Datagrid>
                        <TextField source="category" sortable={false}/>
                        <TextField source="humanTimestamp" label="Timestamp"/>
                        <TextField source="humanDuration" label="Duration" sortable={false}/>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>)}
            {is_tenant_member && (<Tab label="Graphs" path="graph">
                <CookDistHistField binEdgeSource="cookTimeHistBins" binValueSource="cookTimeHistValues"/>
            </Tab>)}
            {is_tenant_member && (
                <Tab label="Pings" path="pings">
                    <ReferenceManyField
                        reference="pings" target="device_id" source="id" addLabel={false}
                        pagination={<PrevNextPagination/>}
                        sort={{ field: 'timestamp', order: 'DESC' }}
                    >
                        <Datagrid rowClick={"show"}>
                            <DateField source="timestamp" showTime/>
                            <NumberField source="voltagePercent" sortable={false}/>
                            <NumberField source="usageTimeHours" sortable={false}/>
                            <BooleanField source="isInitialPing" sortable={false}/>
                            <TextField source="pingReportMethod" sortable={false}/>
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            )}
            {is_tenant_member && (
                <Tab label="First use" path="firstuses">
                    <ReferenceManyField
                        reference="firstuses" target="device_id" source="id" addLabel={false}
                        pagination={<PrevNextPagination/>}
                        sort={{ field: 'timestamp', order: 'DESC' }}
                    >
                        <Datagrid rowClick={"show"}>
                            <DateField source="timestamp" showTime/>
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            )}
            {is_tenant_member && (
                <Tab label="Updates" path="updates">
                    <TextField source="firmwareVersion.s" label="Last reported firmware version"/>
                    <TextField source="algoVersion.s" label="Last reported algorithm version"/>
                    <ReferenceField source="lastConfirmedProfileId" reference="parameterprofiles" label="Parameter profile" link="show">
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="lastAssignedProfileId" reference="parameterprofiles" label="Target parameter profile" link="show">
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceManyField
                        reference="parameterupdates" target="device_id" source="id" label="Parameter updates">
                        <ParameterUpdateDatagrid />
                    </ReferenceManyField>
                    `{props.permissions && props.permissions.isRoot && !props.permissions.isReadOnly && <CreateParameterUpdateButton />}
                </Tab>
            )}
        </TabbedShowLayout>
    </Show>
)};


const DeviceFilter: React.FC<Omit<FilterProps, 'children'>> = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <BooleanInput source="hasFirstUse" label="Has First Use" defaultValue={true} />
        <BooleanInput source="hasLinkedUsers" label="Has Linked Users" defaultValue={true} />
    </Filter>
);


const DeviceListBulkActionButtons: React.FC<React.ComponentProps<typeof BulkExportButton>> = props => {
    return (
        <>
            <PrintQrButton deviceIds={props.selectedIds as string[]} />
            <BulkExportButton {...props} />
        </>
    );
}


const getDeviceExporter = (permissions?: AuthPermissions): Exporter | undefined => {
    if (!(permissions && permissions.loggedIn && permissions.canSeePersonalData)) {
        return undefined;
    }

    return async (records, fetchRelatedRecords, dataProvider) => {
        const modifiedRecords = await Promise.all(records.map(async (record: Record) => {
            const users = await dataProvider.getList('users', {
                pagination: {
                    page: 1,
                    perPage: 25
                },
                sort: {
                    field: 'id',
                    order: 'DESC'
                },
                filter: {device_id: record.id},
            })
            const {stateHistory, nuisanceStateHistory, cookTimeHistBins, cookTimeHistValues, ...restOfRecord} = record;
            return {
                ...restOfRecord,
                user0GivenName: users.data[0] && users.data[0].givenName,
                user0FamilyName: users.data[0] && users.data[0].familyName,
                user0PhoneNumber: users.data[0] && users.data[0].phone_number,
                user1GivenName: users.data[1] && users.data[1].givenName,
                user1FamilyName: users.data[1] && users.data[1].familyName,
                user1honeNumber: users.data[1] && users.data[1].phone_number,
                user2GivenName: users.data[2] && users.data[2].givenName,
                user2FamilyName: users.data[2] && users.data[2].familyName,
                user2PhoneNumber: users.data[2] && users.data[2].phone_number,
            }
        }))

        return jsonExport(modifiedRecords, {}, (err: Error, csv: any) => {
            downloadCSV(csv, 'devices');
        });
    };
}

export const DeviceList: React.FC<ListProps> = props => {
    const { permissions } : { permissions?: AuthPermissions } = usePermissions();
    return (
        <List {...props} actions={<DeviceListActions permissions={permissions} {...props}/>} bulkActionButtons={<DeviceListBulkActionButtons selectedIds={[]} />} filter={{join_users: 'true'}} filters={<DeviceFilter/>} exporter={getDeviceExporter(permissions)}>
            <Datagrid rowClick={"show"}>
                <TextField source="id"/>
                <TextField source="identifier" sortable={false}/>
                {permissions && permissions.loggedIn && permissions.canSeePersonalData && <ArrayField source={"users"} sortable={false}>
                    <SingleFieldList linkType={false}>
                        <ChipField source={"givenName"} />
                    </SingleFieldList>
                </ArrayField>
                }
                <DateField source="firstUpdate" showTime sortable={false}/>
                <DateField source="lastUpdate" showTime sortable={false}/>
                <DateField source="firstUse" showTime sortable={false}/>
                <TextField source="numCookEvents" sortable={false}/>
                <TextField source="totalAlerts" sortable={false}/>
                <DateField source="dateCreated" sortable={false}/>
            </Datagrid>
        </List>
    )
};


const validateProcessorId = (value: string): string | undefined => {
    const isValid = /^[0-9A-Fa-f]{24}$/.test(value);
    if (isValid) {
        return undefined;
    }
    return "Must only contain hex characters 0-9 A-F a-f";
};

const validateIdentifier = (value: string): string | undefined => {
    const isValid = /^b8[a-f0-9]{10}$|^447[0-9]{9}$|^20404[0-9]{10}$|^testing-[a-zA-Z0-9-]{8,64}$/.test(value);
    if (isValid) {
        return undefined;
    }
    return "Must match one of the following: " +
        "[IMSI] 15 digits starting 20404 / " +
        "[Phone] 12 digits starting 447 / " +
        "[pi MAC] 12 lowercase hex characters starting b8 ";
    // Note a string starting testing- is also valid but we don't put this in the helper text.
}

function parseIntTextInput(v: string) {
    const vInt = parseInt(v);
    if (Number.isNaN(vInt)) {
        return v;
    } else {
        return vInt;
    }
}

type DeviceFormProps = Omit<React.ComponentProps<typeof SimpleForm>, 'children'> & {
    isCreate: boolean;
}
const DeviceForm: React.FC<DeviceFormProps> = ({isCreate, ...props}) => {
    const initialTagsValue = React.useMemo(() => [], [])

    return (
        <SimpleForm {...props}>
            <TextInput source="identifier" label="Device identifier"
                       helperText="This is used to know where an incoming message is coming from. It's typically the phone number or IMSI of the device."
                       validate={[required(), validateIdentifier]}/>
            <TextInput source="processorId" label="Processor ID"
                       helperText="This is the 92-bit processor ID of the device, entered as a hex string"
                       validate={[required(), minLength(24), maxLength(24), validateProcessorId]}/>
            <ArrayInput source="tags" label={"Tags"} initialValue={initialTagsValue}>
                <SimpleFormIterator>
                    <TextInput source="name" label="Tag Name" validate={[required()]}/>
                    <TextInput source="value" label="Tag Value" validate={[required()]}/>
                </SimpleFormIterator>
            </ArrayInput>
            <NullableTextInput source="comments" validate={[maxLength(20000)]} multiline={true} fullWidth={true} minRows={3} initialValue={null} />
            {isCreate && (
                <ReferenceInput label="Hardware Version" source="hardwareVersion" reference="hardwareversions">
                    <SelectInput optionText="name"/>
                </ReferenceInput>
            )}
            {isCreate && <TextInput source="firmwareVersion.major" label="Firmware version (major)"
                                    validate={[required(), number(), minValue(0), maxValue(255), regex(/^\d+$/, 'Must be an integer')]}
                                    parse={parseIntTextInput}/>}
            {isCreate && <TextInput source="firmwareVersion.minor" label="Firmware version (minor)"
                                    validate={[required(), number(), minValue(0), maxValue(255), regex(/^\d+$/, 'Must be an integer')]}
                                    parse={parseIntTextInput}/>}
            {isCreate && <TextInput source="algoVersion.major" label="Algo version (minor)"
                                    validate={[required(), number(), minValue(0), maxValue(255), regex(/^\d+$/, 'Must be an integer')]}
                                    parse={parseIntTextInput}/>}
            {isCreate && <TextInput source="algoVersion.minor" label="Algo version (minor)"
                                    validate={[required(), number(), minValue(0), maxValue(255), regex(/^\d+$/, 'Must be an integer')]}
                                    parse={parseIntTextInput}/>}
            {!isCreate && (
                <ReferenceInput label="Tenant ID" source="tenantId" reference="tenants" allowEmpty>
                    <AutocompleteInput optionText="id"/>
                </ReferenceInput>
            )}
            {!isCreate && (
                <DeleteDeviceHistoryButton />
            )}
        </SimpleForm>
    )
}

export const DeviceEdit: React.FC<React.ComponentProps<typeof Edit>> = (props) => (
    <Edit {...props}>
        <DeviceForm isCreate={false} toolbar={<ToolbarWithoutDelete />}/>
    </Edit>
)

export const DeviceCreate: React.FC<React.ComponentProps<typeof Create>> = (props) => (
    <Create {...props}>
        <DeviceForm isCreate={true} redirect={"show"} />
    </Create>
);

const useStyles = makeStyles({
    createParameterUpdateButton: {
        marginTop: "1em",
        marginBottom: "1em",
        color: "white",
    },
});
const CreateParameterUpdateButton = React.forwardRef<any, React.ComponentPropsWithRef<typeof Button>>(({record}, ref) => {
    const classes = useStyles();
    if (!record) {
        return null;
    }
    return(
        <Button
            ref={ref}
            className={classes.createParameterUpdateButton}
            variant="contained"
            component={Link}
            to={{
                pathname: "/parameterupdates/create",
                search: '?' + stringify({device_id: record.id}),
            }}
            label="Assign profile"
        >
            <AddIcon />
        </Button>
)});


const DeviceListActions: React.FC<ListProps> = (props) => {
    const redirect = useRedirect();

    const permissions: AuthPermissions | undefined = props.permissions;

    return (
        <TopToolbar>
            {permissions && permissions.loggedIn && !permissions.isReadOnly && permissions.isRoot && (
                <Button
                    onClick={() => redirect('/importdevices')}
                    label="Import"
                >
                    <PublishIcon/>
                </Button>
            )}
            {React.cloneElement((props as any).filters, { context: 'button' })}
            {permissions && permissions.loggedIn && !permissions.isReadOnly && permissions.isRoot && (
                <CreateButton/>
            )}
            <ExportButton/>
        </TopToolbar>
    );
}

type DeviceState = "CREATED" | "SHIPPED" | "DELIVERED" | "PINGED" | "FITTED_NO_SIGNAL" | "ACTIVE"
type NuisanceState = "NO_NUISANCE" | "NUISANCE"


function deviceStateToTransitionInfo(currentDeviceState: DeviceState): {buttonText: string, targetState: DeviceState}[] {
    switch (currentDeviceState) {
        case "CREATED":
            return [{buttonText: "Mark as shipped", targetState: "SHIPPED"}, {buttonText: "Mark as fitted", targetState: "FITTED_NO_SIGNAL"}];
        case "SHIPPED":
            return [{buttonText: "Mark as delivered", targetState: "DELIVERED"}, {buttonText: "Mark as fitted", targetState: "FITTED_NO_SIGNAL"}];
        case "DELIVERED":
            return [{buttonText: "Mark as fitted", targetState: "FITTED_NO_SIGNAL"}]
        case "PINGED":
            // Intentional fall through
        case "FITTED_NO_SIGNAL":
            // Intentional fall through
        case "ACTIVE":
            return []
        default:
            throw new UnreachableCaseError(currentDeviceState);
    }
}

function nuisanceStateToTransitionInfo(currentNuisanceState: NuisanceState): {buttonText: string, targetState: NuisanceState}[] {
    switch (currentNuisanceState) {
        case "NUISANCE":
            return [{buttonText: "Mark nuisance resolved", targetState: "NO_NUISANCE"}];
        case "NO_NUISANCE":
            return [{buttonText: "Mark as nuisance", targetState: "NUISANCE"}];
        default:
            throw new UnreachableCaseError(currentNuisanceState);
    }
}

const TransitionDeviceStateButton: React.FC = () => {
    const record = useRecordContext();
    const deviceId = record && record.id as string
    const currentDeviceState = record && record.stateHistory[0].state;

    if (deviceId === undefined || currentDeviceState === undefined) {
        return null;
    }

    const transitionInfo = deviceStateToTransitionInfo(currentDeviceState);
    return (<>
        {
            transitionInfo.map((data, i) => <TransitionStateButton key={i} deviceId={deviceId} buttonText={data.buttonText} deviceState={data.targetState} />)
        }
        </>
    );
}

const TransitionNuisanceStateButton: React.FC = () => {
    const record = useRecordContext();
    const deviceId = record && record.id as string
    const currentNuisanceState = record && record.nuisanceStateHistory[0].state;

    if (deviceId === undefined || currentNuisanceState === undefined) {
        return null;
    }

    const transitionInfo = nuisanceStateToTransitionInfo(currentNuisanceState);
    return (<>
        {
            transitionInfo.map((data, i) => <TransitionStateButton key={i} deviceId={deviceId} buttonText={data.buttonText} nuisanceState={data.targetState} />)
        }
        </>
    );
}

type TransitionStateButtonProps = {
    deviceId: string
    buttonText: string
    deviceState?: DeviceState
    nuisanceState?: NuisanceState
}
const TransitionStateButton: React.FC<TransitionStateButtonProps> = ({deviceId, buttonText, deviceState, nuisanceState}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const transitionDeviceState = async () => {
        setIsLoading(true);

        let data: {deviceState?: DeviceState, nuisanceState?: NuisanceState} = {}
        if (deviceState) {
            data = {...data, deviceState: deviceState}
        }
        if (nuisanceState) {
            data = {...data, nuisanceState: nuisanceState}
        }
        try {
            await dataProvider.post(`devices/${deviceId}/stateupdate`, data);
            refresh();
        } catch {
            notify("State update failed",  'error')
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Button
            variant={'contained'}
            color={'primary'}
            onClick={transitionDeviceState}
            disabled={isLoading}
            label={isLoading ? "Loading..." : buttonText}
            style={{marginLeft: 5, marginRight: 5}}
        >
            <LabelIcon/>
        </Button>
    )
}


type DeleteDeviceHistoryButtonProps = {}
const DeleteDeviceHistoryButton: React.FC<DeleteDeviceHistoryButtonProps> = () => {
    const record = useRecordContext();
    const history = useHistory();

    const [isLoading, setIsLoading] = React.useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    if (!record) {
        return null;
    }
    const deviceId = record.id;

    const deleteHistory = async () => {
        setIsLoading(true);

        const data = {};
        try {
            await dataProvider.post(`devices/${deviceId}/deletehistory`, data);
            refresh();
            notify("History successfully deleted", 'success');
            history.push(`/devices/${deviceId}/show`);
        } catch {
            notify("Delete history failed. History may be partially deleted. It is safe to retry the delete to clear the remainder.",  'error');
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Button
                variant={'contained'}
                onClick={deleteHistory}
                disabled={isLoading}
                label={isLoading ? "Loading..." : 'Delete history'}
                style={{marginLeft: 5, marginRight: 5, color: 'white', backgroundColor: 'red'}}
            >
                <WarningIcon />
            </Button>
            <div style={{color: 'red', maxWidth: '400px'}}>Warning: this will delete all alerts, pings, and other device related history. It is possible to change the tenant ID without deleting the history. You cannot change the tenant ID if the device is linked to one or more users.</div>
        </>
    )
}