export interface WebClientConfig {
    IS_USER: boolean
    API_URL: string
    FEEDBACK_URL: string
    USER_POOL_ID: string
    USER_POOL_WEB_CLIENT_ID: string
    AWS_REGION: string
}

export const getConfigAsync = function() {
    let configPromise: Promise<WebClientConfig> | null = null;

    return async () => {
        if (configPromise === null) {
            configPromise = fetch('/config.json').then(res => res.json());
        }
        return await configPromise;
    }
}();
