import React from 'react';
import CustomAppBar from './AppBar';
import { Layout } from 'react-admin';

const CustomLayout: React.FC = props => {
    return (
        <Layout {...props} appBar={CustomAppBar} />
    )
};

export default CustomLayout;
