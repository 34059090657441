import React from "react";
import {Button, HttpError, TextFieldProps, useDataProvider} from "react-admin";
import ReplayIcon from "@material-ui/icons/Replay";

const RefreshTemporaryPasswordButton: React.FC<TextFieldProps & {isUser: boolean}> = ({record, isUser}) => {
    const dataProvider = useDataProvider();
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState<string | null>(null);

    const onClick = React.useCallback(() => {
        if (record) {
            console.log(record);
            setLoading(true);
            setMessage(null);
            (dataProvider as any).refreshTemporaryPassword(record.id, isUser).then((response: any) => {
                setLoading(false);
                setMessage("Temporary password reset and welcome email resent");
            }).catch((err: HttpError) => {
                setLoading(false);
                if (err.status === 400) {
                    setMessage(err.body.message);
                }
            })
        }
    }, [record, dataProvider, isUser])

    return <div style={{marginTop: '10px'}}>
        <Button variant="contained" label="Refresh temporary password" disabled={loading} onClick={onClick}><ReplayIcon/></Button>
        {message && <div>{JSON.stringify(message)}</div>}
    </div>
}

export default RefreshTemporaryPasswordButton