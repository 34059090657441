import { Auth } from "@aws-amplify/auth";
import dataProvider from './dataProvider'
import {WebClientConfig} from "./config";

export const initAuth = function(config: WebClientConfig) {
    Auth.configure({
        // REQUIRED - Amazon Cognito Region
        region: config.AWS_REGION,
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: config.USER_POOL_ID,
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: config.USER_POOL_WEB_CLIENT_ID,
    })
};

export type AccessLevel = "ROOT" | "ROOT_READ_ONLY" | "ROOT_READ_ONLY_NO_PERSONAL_DATA" | "ADMIN" | "ADMIN_READ_ONLY" | "READ_ONLY" | "TENANT_MEMBER" | "NONE"
export type AuthPermissions = {
    loggedIn: true
    isUser: boolean
    isTenantMember: boolean
    isRoot: boolean
    isReadOnly: boolean
    canSeePersonalData: boolean
    isAdmin: boolean
    id: string
    accessLevel: AccessLevel
    becomeAccessLevel: AccessLevel
} | {
    loggedIn: false
}

export interface AuthProviderOptions {
    isUser: boolean
}

const defaultOptions: AuthProviderOptions = {
    isUser: true,
};

export class AuthProvider {
  public isUser: boolean;
  private accessLevelCache: {[k: string]: string} = {}


  public constructor(options: AuthProviderOptions) {
    const optionsBag = { ...defaultOptions, ...options };

    this.isUser = optionsBag.isUser
  }

  // called when the user attempts to log in
  public login = async (): Promise<void> => {
    console.log('LOGIN');
    await Auth.currentSession();
  };

  // called when the user clicks on the logout button
  public logout = async (): Promise<any> => {
    console.log('LOGOUT');
    await Auth.signOut();
  };

  // called when the user navigates to a new location, to check for authentication
  public checkAuth = async (): Promise<void> => {
    console.log('CHECK_AUTH');
    try {
        await Auth.currentSession();
    } catch {
        return Promise.reject()
    }
  };

  // called when the API returns an error
  public checkError = (error: Record<string, unknown>): Promise<void> => {
    console.log('auth err: ' + JSON.stringify(error));
    if (error.status === 401 || error.status === 403) {
        return Promise.reject();
    } else {
        return Promise.resolve();
    }
  };

  public getPermissions = async(): Promise<AuthPermissions> => {
        console.log('GET_PERMISSIONS');
        let username: string = '';
        try {
            const accessToken = await getAccessToken();
            username = accessToken.username;
        } catch {
            return {loggedIn: false};
        }
        if (this.isUser) {
            return {
                loggedIn: true,
                isUser: true,
                isTenantMember: false,
                isRoot: false,
                isReadOnly: false,
                isAdmin: false,
                canSeePersonalData: true,
                id: username,
                accessLevel: "NONE",
                becomeAccessLevel: "NONE"
            }
        } else {
            let accessLevel: AccessLevel = "NONE"
            if (this.accessLevelCache[username] !== undefined) {
                accessLevel = this.accessLevelCache[username] as AccessLevel
            } else {
                const memberData = await dataProvider.getOneNoTenantSimulation('members', {id: username});
                accessLevel = memberData.data.accessPolicy.accessLevel;
                this.accessLevelCache[username] = accessLevel;
            }
            return {
                loggedIn: true,
                isUser: false,
                isTenantMember: true,
                isRoot: ["ROOT", "ROOT_READ_ONLY", "ROOT_READ_ONLY_NO_PERSONAL_DATA"].includes(accessLevel),
                isReadOnly: ["ROOT_READ_ONLY", "ROOT_READ_ONLY_NO_PERSONAL_DATA", "ADMIN_READ_ONLY", "READ_ONLY"].includes(accessLevel),
                isAdmin: ["ROOT", "ROOT_READ_ONLY", "ROOT_READ_ONLY_NO_PERSONAL_DATA", "ADMIN", "ADMIN_READ_ONLY"].includes(accessLevel),
                canSeePersonalData: ["TENANT_MEMBER", "READ_ONLY", "ADMIN_READ_ONLY", "ADMIN", "ROOT_READ_ONLY", "ROOT"].includes(accessLevel),
                id: username,
                accessLevel: accessLevel,
                becomeAccessLevel: dataProvider.getBecomeTenant() === null ? accessLevel : "ADMIN",
            }
        }
    }
}


let accessTokenPromise: Promise<{accessToken: string, username: string}> | null = null;

export function getAccessToken(): Promise<{accessToken: string, username: string}> {
    if (accessTokenPromise !== null) {
        return accessTokenPromise;
    }
    accessTokenPromise = Auth.currentSession().then((session) => {
        const accessToken = session.getAccessToken();
        accessTokenPromise = null;
        return {accessToken: accessToken.getJwtToken(), username: accessToken.decodePayload()['username']};
    }).catch((err) => {
        accessTokenPromise = null;
        throw err;
    });
    return accessTokenPromise;
}

export async function changePassword(oldPassword: string, newPassword: string) {
    const currentUser = await Auth.currentAuthenticatedUser();
    try {
        await Auth.changePassword(currentUser, oldPassword, newPassword);
    } catch (err) {
        throw err;
    }
}

export const TenantMemberAuthProvider = new AuthProvider({isUser: false});
export const UserAuthProvider = new AuthProvider({isUser: true});
