import React from 'react'
import {
    List,
    Show,
    SimpleShowLayout,
    Datagrid,
    TextField,
    EmailField,
} from 'react-admin';
import { Edit, EditButton, SimpleForm, TextInput, SelectInput, ReferenceInput } from 'react-admin';
import { Create, TitleProps } from 'react-admin';
import {required, minLength, maxLength, email, choices} from 'react-admin';
import {validateNullablePhone} from './validation';
import RefreshTemporaryPasswordButton from './RefreshTemporaryPasswordButton';


export const MemberList: React.FC<React.ComponentProps<typeof List>> = props => (
    <List {...props}
          title={"Organisation"}
          perPage={25}
          bulkActionButtons={props.permissions && !props.permissions.isReadOnly ? undefined : false}>
        <Datagrid rowClick={"show"}>
            <TextField source="name"/>
            <EmailField source="email"/>
            <TextField source="accessPolicy.accessLevel" label="Access level"/>
            {props.permissions && !props.permissions.isReadOnly && <EditButton/>}
        </Datagrid>
    </List>
);


export const MemberShow: React.FC<React.ComponentProps<typeof Show>> = props => (
    <Show {...props} title={<MemberItemTitle />}>
        <SimpleShowLayout>
            <TextField source="name"/>
            <EmailField source="email"/>
            <TextField source="accessPolicy.accessLevel" label="Access level"/>
            <TextField source="phoneNumber" emptyText={'Provide a phone number to use SMS for MFA. Without a number, an authenticator app must be used.'}/>
            <RefreshTemporaryPasswordButton isUser={false}/>
        </SimpleShowLayout>
    </Show>
);

function parseNullableString(v: string) {
    if (v.length === 0) {
        return null;
    }
    return v;
}

const validateAccessLevel = choices(['NONE', 'ROOT', 'ROOT_READ_ONLY', 'ROOT_READ_ONLY_NO_PERSONAL_DATA', 'ADMIN', 'ADMIN_READ_ONLY', 'READ_ONLY', 'TENANT_MEMBER'], 'Must be a valid access level');
const MemberForm: React.FC<Omit<React.ComponentProps<typeof SimpleForm>, 'children'>> = props => (
    <SimpleForm {...props} redirect="show">
        <TextInput source="name" validate={[required(), minLength(1), maxLength(50)]}/>
        <TextInput disabled={props.allowemailedit !== true.toString()} source="email" validate={[required(), email()]}/>
        <ReferenceInput label="Access level" source="accessPolicy.accessLevel" reference={"accesslevels"} validate={validateAccessLevel} defaultValue={"NONE"}>
            <SelectInput optionText={"name"}/>
        </ReferenceInput>
        <TextInput source="phoneNumber"
                   parse={parseNullableString}
                   initialValue={null}
                   validate={[validateNullablePhone]}
                   helperText={'If a phone number is provided, text messages will be sent to this number to be used for multi-factor authentication (MFA) during dashboard login. If no phone number is provided, the member will be required to set up one time passwords using Google Authenticator or an equivalent app.'}/>
    </SimpleForm>
);

export const MemberEdit: React.FC<React.ComponentProps<typeof Edit>> = props => (
    <Edit {...props} title={<MemberItemTitle/>}>
        <MemberForm />
    </Edit>
);

export const MemberCreate: React.FC<React.ComponentProps<typeof Create>> = props => (
    <Create {...props}>
        <MemberForm allowemailedit={true.toString()} />
    </Create>
);

const MemberItemTitle: React.FC<TitleProps> = ({record}) => (
    <span>Member{record ? `: ${record.name}` : ''}</span>
)
