import React from "react";
import {List, useRedirect} from "react-admin";

export const BlankList: React.FC<React.ComponentProps<typeof List>> = props => {
    const redirect = useRedirect();

    React.useEffect(() => {
        redirect('/login')
    }, [redirect])

    return null;
};