import React from 'react';
import {Create, SimpleForm, PasswordInput, useNotify, useRefresh} from 'react-admin';
import {required, minLength, regex} from 'react-admin';
import { RouteComponentProps } from 'react-router';


type ChangePasswordFormValues = {oldPassword?: string, newPassword?: string, confirmNewPassword?: string}

const confirmNewPasswordValidation = (value: string, allValues: ChangePasswordFormValues) => {
    if (value !== allValues.newPassword) {
        return 'Passwords must match';
    }
    return undefined;
};

const newPasswordValidation = [
    required(),
    minLength(8),
    regex(/[a-z]/, 'Must contain a lower case letter'),
    regex(/[A-Z]/, 'Must contain an upper case letter'),
    regex(/[0-9]/, 'Must contain a number'),
    regex(/[-!$%^&*()_|~`{}\[\]:\/;<>?,.@#'"\\]/, 'Must contain a special character') // eslint-disable-line no-useless-escape
];

const ChangePassword: React.FC<RouteComponentProps> = ({staticContext, ...props}) => {
    const notify = useNotify();
    const refresh = useRefresh();

    const onSuccess = React.useCallback(() => {
        notify('Password successfully updated')
        refresh();
    }, [notify, refresh]);

    return (
        <Create resource={'changepassword'} basePath={'/changepassword'} title={'Change Password'} {...props} onSuccess={onSuccess}>
            <SimpleForm>
                <PasswordInput source={'oldPassword'} validate={[required()]}/>
                <PasswordInput source={'newPassword'} validate={newPasswordValidation}/>
                <PasswordInput source={'confirmNewPassword'} validate={[required(), confirmNewPasswordValidation]}/>
            </SimpleForm>
        </Create>
    )
}

export default ChangePassword;
