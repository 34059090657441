import React from 'react'
import parseQueryString from "./common/parseQueryString";
import {NullableTextInput} from "./common"
import {validatePhone} from "./validation";
import {
    SelectInput,
    useGetList,
    ArrayField,
    Datagrid,
    TextField,
    Edit,
    EditButton,
    SimpleForm,
    ArrayInput,
    SimpleFormIterator,
    BooleanInput,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    Create,
    required,
    minLength,
    maxLength,
    TitleProps,
    useGetOne,
    SimpleList,
    Record,
    Toolbar,
    SaveButton,
    DeleteWithConfirmButton,
    useRedirect,
} from 'react-admin';
import {AuthPermissions} from './authProvider';
import PanLocationsInput from "./PanLocationsInput";
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import {useTheme} from '@material-ui/styles';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ThemedEditButton from "./common/ThemedEditButton";
import ThemedBackButton from "./common/ThemedBackButton";


export const UserDeviceSimpleList: React.FC<React.ComponentProps<typeof SimpleList> & {permissions: AuthPermissions}> = props => {
    const theme: any = useTheme();
    const primaryColor = theme.palette.primary.main
    const errorColor = theme.palette.error.main
    const redirect = useRedirect();

    return (
        <SimpleList
            linkType={false}
            primaryText={(record: any) => (
                <>
                    <Box onClick={() => redirect(`/devices/${record.deviceId}/show`)}>
                        <Typography variant={'subtitle2'}>{`${record.friendlyName}`}</Typography>
                        {record.contactNumbers.map((contactNumber: any) => (
                            <Box style={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                                <Typography variant={'body2'}>{`${contactNumber.phoneNumber}`}</Typography>
                                {contactNumber.isPhone ? <PhoneInTalkIcon style={{color: primaryColor}} /> : <PhoneDisabledIcon style={{color: errorColor}} />}
                                {contactNumber.isSms ? <SpeakerNotesIcon style={{color: primaryColor}} /> : <SpeakerNotesOffIcon style={{color: errorColor}} />}
                            </Box>
                        ))}
                    </Box>
                    { props.permissions && props.permissions.loggedIn && !props.permissions.isReadOnly && <ThemedEditButton resource={props.resource} variant={'contained'} /> }
                    {props.permissions && props.permissions.loggedIn && !props.permissions.isReadOnly && <DeleteWithConfirmButton
                        variant={'contained'}
                        resource={props.resource}
                        record={record}
                        redirect={false}
                        label={'Remove device'}
                        confirmTitle={'Are you sure you want to remove this device from your account?'}
                        confirmContent={'You will no longer receive text or phone alerts from this device, and any device settings will be lost. To link the device with your account again you will need to re-enter the Pippa ID printed on your Pippa, or use the Pippa app to scan the QR code on your Pippa.'}
                    />}
                </>
            )}
        />
    )
}


export const UserDeviceDatagrid: React.FC<React.ComponentProps<typeof Datagrid> & { permissions: AuthPermissions }> = props => {
    const theme: any = useTheme();
    const primaryColor = theme.palette.primary.main
    const errorColor = theme.palette.error.main

    return (
        <Datagrid {...props} rowClick={(id, basePath, record) => `/devices/${record.deviceId}/show`}>
            {props.permissions && props.permissions.loggedIn && props.permissions.isTenantMember &&
            <TextField source="deviceId"/>}
            <TextField source="friendlyName" label="Name" sortable={false}/>

            <ArrayField source="contactNumbers" sortable={false}>
                <SimpleList
                    linkType={false}
                    primaryText={(record) => (
                        <Box style={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                            <Typography component={'span'} variant={'body2'}>{`${record.phoneNumber}`}</Typography>
                            {record.isPhone ? <PhoneInTalkIcon style={{color: primaryColor}} /> : <PhoneDisabledIcon style={{color: errorColor}} />}
                            {record.isSms ? <SpeakerNotesIcon style={{color: primaryColor}} /> : <SpeakerNotesOffIcon style={{color: errorColor}} />}
                        </Box>
                    )}
                />
            </ArrayField>
            {props.permissions && props.permissions.loggedIn && !props.permissions.isReadOnly && <EditButton/>}
            {props.permissions && props.permissions.loggedIn && !props.permissions.isReadOnly && <DeleteWithConfirmButton
                redirect={false}
                label={'Remove device'}
                confirmTitle={'Are you sure you want to remove this device from your account?'}
                confirmContent={'You will no longer receive text or phone alerts from this device, and any device settings will be lost. To link the device with your account again you will need to re-enter the Pippa ID printed on your Pippa, or use the Pippa app to scan the QR code on your Pippa.'}
            />}
        </Datagrid>
    )
}

function validateDeviceId(existingDeviceIds: string[]): (v: string) => string | undefined {
    return (v: string) => {
        if (existingDeviceIds.indexOf(v) >= 0) {
            return "This device is already linked to this user";
        }
        return undefined;
    }
}

const UserDeviceForm: React.FC<Omit<React.ComponentProps<typeof SimpleForm>, 'children'> & {isCreate: boolean}> = props => {
    const { data } = useGetList('userdevices', undefined, undefined, {user_id: props.record.userId || props.userId}, {});
    const existingDeviceIds = data ? Object.entries(data).map(([id, d]) => d.deviceId) : [];

    const initialContactNumbersValue = React.useMemo(() => [{}], []);

    return (
        <SimpleForm {...props}
                    redirect={props.record.userId ? `/users/${props.record.userId}/show` : props.userId ? `/users/${props.userId}/show` : '/users'}>
            {
                props.isCreate ? (
                    props.permissions && props.permissions.isTenantMember ? (
                        <ReferenceInput label="Device ID" source="deviceId" reference="devices">
                            <AutocompleteInput optionText="id" validate={[required(), validateDeviceId(existingDeviceIds)]}/>
                        </ReferenceInput>
                    ) : (
                        <TextInput label="Device ID" source="deviceId"
                                   validate={[required(), minLength(8), maxLength(64), validateDeviceId(existingDeviceIds)]}/>
                    )
                ) : (
                    props.permissions && props.permissions.isTenantMember && <TextField source="deviceId" />
                )
            }

            <NullableTextInput source="friendlyName" label="Friendly name" validate={[minLength(1), maxLength(30)]}
                               initialValue={null}/>
            <ArrayInput source={"contactNumbers"} label="Contact numbers" initialValue={initialContactNumbersValue}>
                <SimpleFormIterator>
                    <TextInput source={"phoneNumber"} label="Phone number" validate={[required(), validatePhone as any]}
                               initialValue={props.initialPhoneNumber}/>
                    <BooleanInput source="isSms" label="Text alerts" initialValue={true}/>
                    <BooleanInput source="isPhone" label="Phone alerts" initialValue={false}/>
                </SimpleFormIterator>
            </ArrayInput>
            {props.permissions && props.permissions.isTenantMember && (
                <>
                    <SelectInput source="calibration.hobLayoutId" label="Hob layout" initialValue={null} choices={[
                        {id: null, name: 'Not calibrated'},
                        {id: 0, name: '4 ring 2x2'},
                        {id: 1, name: '6 ring (3 wide, 2 deep)'},
                        {id: 2, name: '5 ring big middle'},
                        {id: 3, name: '5 ring big left'},
                        {id: 4, name: '5 ring big right'},
                        {id: 5, name: '2 ring vertical'},
                        {id: 6, name: '2 ring horizontal'},
                    ]} />
                    <PanLocationsInput />
                </>
            )}
        </SimpleForm>
    )
};

const UserDeviceEditToolbar: React.FC<React.ComponentProps<typeof Toolbar>> = (props) => {
    return (
        <Toolbar {...props}>
            <SaveButton style={{marginRight: '10px'}} />
            <ThemedBackButton />
        </Toolbar>
    );
};

export const UserDeviceEdit: React.FC<React.ComponentProps<typeof Edit>> = props => {
    const isTenantMember = props.permissions && props.permissions.isTenantMember;
    return (
        <Edit undoable={isTenantMember} {...props} title={<UserDeviceItemTitle/>}>
            <UserDeviceForm
                toolbar={<UserDeviceEditToolbar />}
                isCreate={false}
                permissions={props.permissions}
            />
        </Edit>
    );
};

export const UserDeviceCreate: React.FC<React.ComponentProps<typeof Create>> = props => {
    const {user_id: userId, phone_number: phoneNumberString} = parseQueryString(props);

    if (!userId) {
        throw new Error("A link must be created for a specified user")
    }
    const phoneNumber = phoneNumberString ? phoneNumberString : '';

    const initialValues = React.useMemo(() => ({userId}), [userId]);

    return (
        <Create {...props} title={<UserDeviceCreateTitle userId={userId as string}/>}>
            <UserDeviceForm permissions={props.permissions}
                            isCreate={true}
                            initialValues={initialValues}
                            userId={userId}
                            initialPhoneNumber={phoneNumber}
            />
        </Create>
    )
};

const UserDeviceItemTitle: React.FC<TitleProps> = (props) => {
    if (props.record === undefined) {
        return <span>User-device link</span>
    } else {
        return <UserDeviceItemTitleInner {...props} record={props.record}/>
    }
}

const UserDeviceItemTitleInner: React.FC<TitleProps & { record: Record }> = ({record}) => {
    const {data, loading, error} = useGetOne('users', record.userId);

    return <span>Link {!loading && !error && data ? `from ${data.email} to Pippa ${record.deviceId}` : ''}</span>
}

const UserDeviceCreateTitle: React.FC<TitleProps & { userId: string }> = ({userId}) => (
    <span>Link device to {userId ? `${userId}` : ''}</span>
)
