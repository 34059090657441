import React from 'react'
import { required } from 'react-admin';
import { Edit, SimpleForm, BooleanInput } from 'react-admin';
import { TitleProps } from 'react-admin';

const VodafoneSimForm: React.FC<Omit<React.ComponentProps<typeof SimpleForm>, 'children'>> = props => (
    <SimpleForm {...props} redirect={false}>
        <BooleanInput source="isLive" validate={[required()]} />
    </SimpleForm>
);

export const VodafoneSimEdit: React.FC<React.ComponentProps<typeof Edit>> = props => (
    <Edit {...props} title={<VodafoneSimTitle/>} undoable={false}>
        <VodafoneSimForm />
    </Edit>
);

const VodafoneSimTitle: React.FC<TitleProps> = ({record}) => (
    <span>Vodafone Sim{record ? `: ${record.id}` : ''}</span>
)
