/* Function such that if called consecutively with the same input, returns the last result */
import {makePostRequest} from "./dataProvider";

export type SyncValidator<T = string> = (v: T | undefined) => (string | undefined)

export function simpleMemoize<T, U>(fn: (arg: T) => U): (arg: T) => U {
    let cache = new Map();

    return (arg) => {
        let result = cache.get(arg);
        if (result === undefined) {
            result = fn(arg);
            cache.set(arg, result);
        }
        return result;
    };
}

export const validatePhoneNumber = simpleMemoize(async (value: string) => {
    if (!/^\+[1-9]\d{6,14}$/.test(value)) {
        // We're not ok, don't bother checking with the API
        return false;
    }
    const response = await makePostRequest("validatephonenumber", {'phoneNumber': value});
    if (response.json.isValid === true) {
        return true;
    }
    return false;
});

export const validatePhone = async (value: string): Promise<string | undefined> => {
    const message = "Must be a phone number including international code, starting + with no spaces";
    const valid = await validatePhoneNumber(value);
    if (valid) {
        return undefined;
    }
    return message;
};

export const validateNullablePhone = async (value: string | null): Promise<string | undefined> => {
    if (value === null) {
        return undefined;
    }
    return await validatePhone(value);
}

export function minArrayLength(minLength: number, message?: string): SyncValidator<any[]> {
    return (a) => {
        if (a === undefined || a.length < minLength) {
            return message ? message : `Must have at least ${minLength} ${minLength > 1 ? 'elements' : 'element'}`
        }
        return undefined;
    }

}

export const validateUrl = (value: string): string | undefined => {
    if (value === null) {
        return undefined;
    }
    const valid = /^https:\/\/[-._~A-Za-z0-9#@:&/+%=?]{3,2000}$/.test(value);
    if (valid) {
        return undefined;
    }
    return "Must be a URL starting with https://"
}