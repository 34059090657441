import React from "react";
import {TextInput, Create, Identifier, required, SimpleForm, maxLength, useGetOne} from "react-admin";
import parseQueryString from "./common/parseQueryString";

export const UserSmsCreate: React.FC<React.ComponentProps<typeof Create>> = (props) => {
    const {user_id} = parseQueryString(props);
    const {data: user} = useGetOne('users', user_id as string)

    return (
        <Create {...props} title={"Send SMS"}>
            <SimpleForm
                initialValues={{user_id}}
                redirect={(basePath: string, id: Identifier, data?: { user_id?: string; }) => data && data.user_id ? `/users/${data.user_id}/show` : '/users'}>
                {user && <div>SMS will be sent to {user.givenName} {user.familyName} ({user.phone_number})</div>}
                <TextInput validate={required()} disabled={true} source="user_id" style={{display: 'none'}}/>
                <TextInput multiline fullWidth validate={[required(), maxLength(300)]} label="Message" source="content" />
            </SimpleForm>
        </Create>
    );
};
