import React from 'react';
import {InjectedFieldProps, PublicFieldProps} from "ra-ui-materialui/lib/field/types";
import { useLocation } from 'react-router-dom';
import {
    useDataProvider,
    usePermissions,
    useRefresh,
    email as validateEmail, HttpError,
} from 'react-admin'
import TextField from '@material-ui/core/TextField'
import PaymentIcon from '@material-ui/icons/Payment';
import CloseIcon from '@material-ui/icons/Close';
import {AuthPermissions} from "./authProvider";
import Box from "@material-ui/core/Box";
import Button from "./common/ThemedButton";

export function useManageSubscription() {
    const dataProvider = useDataProvider();
    const [isLoadingSubscriptionManagement, setIsLoadingSubscriptionManagement] = React.useState(false);

    const manageSubscription = React.useCallback(async () => {
        setIsLoadingSubscriptionManagement(true);
        try {
            const returnUrl = window.location.href;
            const {data}: any = await dataProvider.createBillingPortalSession(returnUrl);
            // Navigate to URL for billing portal session
            window.location.href = data.url;
        } finally {
            setIsLoadingSubscriptionManagement(false);
        }
    }, [dataProvider]);
    return {manageSubscription, isLoadingSubscriptionManagement};
}

function delay(delayMs: number) {
    return new Promise(resolve => setTimeout(resolve, delayMs));
}

function useRedeemSubscriptionCode(deviceId: string) {
    const dataProvider = useDataProvider();
    const refresh = useRefresh()
    const [isRedeemingCode, setIsRedeemingCode] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);

    const redeemCode = React.useCallback(async (email: string, code: string) => {
        setIsRedeemingCode(true);
        setError(null);
        try {
            await dataProvider.redeemSubscriptionCode({deviceId}, email, code);
            await delay(10000);
            refresh();
        } catch (e: any) {
            if (e.status >= 500) {
                setError("Internal server error");
            } else {
                setError(e.body?.message || "Unexpected error")
            }
            throw e;
        } finally {
            setIsRedeemingCode(false);
        }
    }, [dataProvider, deviceId, refresh]);

    return {redeemCode, isRedeemingCode, error};
}

interface SubscriptionStatusFieldProps extends PublicFieldProps, InjectedFieldProps {}
export const SubscriptionStatusField: React.FC<SubscriptionStatusFieldProps> = ({record}) => {
    const location = useLocation();
    const urlQueryParams = new URLSearchParams(location.search)
    const autoOpenSubscribe = urlQueryParams.get('subscribe') === '1';
    const [hasAutoOpened, setHasAutoOpened] = React.useState(false);
    urlQueryParams.delete('subscribe')
    const remainingQueryString = urlQueryParams.toString();

    const {permissions}: {permissions?: AuthPermissions} = usePermissions();
    const dataProvider = useDataProvider();
    const currentUserId = permissions === undefined ? '' : permissions.loggedIn ? permissions.id : '';
    const deviceSubscription = getDeviceSubscription(currentUserId, record as Device)

    const {manageSubscription, isLoadingSubscriptionManagement} = useManageSubscription();
    const {redeemCode, isRedeemingCode, error: redeemError} = useRedeemSubscriptionCode(record?.id as string ?? 'undefined');
    const [isLoadingCheckout, setIsLoadingCheckout] = React.useState(false);
    const isLoading = isRedeemingCode || isLoadingSubscriptionManagement || isLoadingCheckout;
    const [showRedeem, setShowRedeem] = React.useState(false);
    const [errorText, setErrorText] = React.useState<string | null>(null);

    // Return URL does not include the subscribe=1 if it was there so that the user doesn't get stuck in a redirect loop
    const returnUrl = window.location.origin + window.location.pathname + (remainingQueryString.length > 0 ? '?' : '') + remainingQueryString;

    const openCheckout = React.useCallback(async () => {
        setIsLoadingCheckout(true);
        setErrorText(null);
        try {
            const deviceId = record?.id
            const {data}: any = await dataProvider.createBillingCheckoutSession(false, deviceId, returnUrl, returnUrl)
            // Navigate
            window.location.href = data.url;
        } catch (e) {
            if (e instanceof HttpError) {
                setErrorText(e.message);
            }
        } finally {
            setIsLoadingCheckout(false);
        }
    }, [record, returnUrl, dataProvider])

    React.useEffect(() => {
        if (!hasAutoOpened && autoOpenSubscribe && !deviceSubscription.isSubscribed && record) {
            openCheckout();
            setHasAutoOpened(true);
        }
    }, [hasAutoOpened, autoOpenSubscribe, deviceSubscription.isSubscribed, record, openCheckout]);

    return (
        <React.Fragment>
            <div>
                {
                    isRedeemingCode
                        ? 'Redeeming subscription code...'
                        : deviceSubscription.isSubscribed
                        ? 'Subscribed to alerts'
                        : 'Not subscribed to alerts'
                }
            </div>
            <div>
                {
                    isRedeemingCode
                        ? ''
                        : deviceSubscription.expiry === null
                        ? ''
                        : deviceSubscription.isSubscribed
                        ? `Expires ${formatDate(deviceSubscription.expiry)}`
                        : `Expired ${formatDate(deviceSubscription.expiry)}`
                }
            </div>
            {deviceSubscription.isSubscribed ? (
                <Button
                    variant={'contained'}
                    disabled={isLoading || !deviceSubscription.isThisUser}
                    onClick={manageSubscription}
                    label={isLoadingSubscriptionManagement ? 'Loading' : 'Manage subscription'}
                >
                    <PaymentIcon />
                </Button>
            ) : (
                <div style={{flexDirection: 'row'}}>
                    <Button
                        variant={'contained'}
                        style={{flex: 1, marginRight: '6px', marginBottom: '6px', ...(errorText && {color: 'red'})}}
                        disabled={isLoading}
                        onClick={openCheckout}
                        label={isLoadingCheckout ? 'Loading' : 'Subscribe'}
                    >
                        <PaymentIcon />
                    </Button>
                    <Button
                        variant={'contained'}
                        style={{flex: 1, marginBottom: '6px'}}
                        disabled={isLoading}
                        onClick={() => setShowRedeem(x => !x)}
                        label={showRedeem ? 'Close' : 'Redeem code'}
                    >
                        {showRedeem ? <CloseIcon /> : <PaymentIcon />}
                    </Button>
                    {showRedeem && <RedeemCodeInputs onRedeem={redeemCode} onClose={() => setShowRedeem(false)} isLoading={isLoading} redeemError={redeemError} />}
                </div>
            )}
            {errorText && <div style={{color: 'red', fontWeight: 'bold'}}>
                {errorText}
            </div>}
            {deviceSubscription.freeExpiry !== null && (
                <div>
                    {deviceSubscription.hasFreeSubscription
                        ? `Free subscription expires ${formatDate(deviceSubscription.freeExpiry)}`
                        : `Free subscription expired ${formatDate(deviceSubscription.freeExpiry)}`}
                </div>
            )}
        </React.Fragment>
    );
};

type RedeemCodeInputsProps = {
    onRedeem: (email: string, code: string) => void;
    onClose: () => void;
    isLoading: boolean;
    redeemError: string | null;
}
const RedeemCodeInputs: React.FC<RedeemCodeInputsProps> = ({onRedeem, onClose, isLoading, redeemError}) => {
    const [email, setEmail] = React.useState('');
    const [code, setCode] = React.useState('');

    const emailError = validateEmail()(email, []);
    const codeError = !/^[ABCDEFGHJKLMNPQRSTUVWXYZ23456789]{8}$/.test(code);

    return (
        <Box component={'div'} border={'2px solid'} borderColor={redeemError !== null ? 'red' : 'black'} sx={{borderRadius: '10px', padding: '10px'}}>
            <TextField
                error={emailError !== undefined}
                label={'Email'}
                variant="outlined"
                value={email}
                helperText={emailError !== undefined ? 'Enter a valid email address' : undefined}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                }}/>
            <div>{'IMPORTANT: Use the email address you used to order the subscription and to which the code was sent'}</div>
            <TextField
                error={codeError}
                label={'Code'}
                variant="outlined"
                value={code}
                helperText={codeError ? 'Enter an 8 digit code containing only letters and numbers' : undefined}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCode(event.target.value.toUpperCase());
                }}/>
            {redeemError !== null && (
                <Box style={{color: 'red', fontWeight: 'bold'}}>
                    {'Failed: ' + redeemError}
                </Box>
            )}
            <div style={{marginTop: '10px'}}>
                <Button
                    variant={'contained'}
                    disabled={isLoading}
                    onClick={() => {
                        onRedeem(email, code)
                    }}
                    label={'Redeem'}
                >
                    <PaymentIcon />
                </Button>
            </div>
        </Box>
    );
}

function formatDate(d: Date): string {
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
}

interface Device {
    id: string;
    subscriptionExpiry: number | null;
    subscriptionUserId: string | null;
    freeSubscriptionExpiry: number | null;
}


function getDeviceSubscription(userId: string, device: Device): {
    isSubscribed: boolean;
    expiry: Date | null;
    isThisUser: boolean;
    freeExpiry: Date | null;
    hasFreeSubscription: boolean;
} {
    const freeExpiry = device.freeSubscriptionExpiry === null ? null : new Date(device.freeSubscriptionExpiry);
    const hasFreeSubscription = device.freeSubscriptionExpiry !== null && device.freeSubscriptionExpiry >= new Date().getTime();

    if (device.subscriptionExpiry === null) {
        return {isSubscribed: false, expiry: null, isThisUser: true, freeExpiry, hasFreeSubscription};
    }
    const isSubscribed = device.subscriptionExpiry >= new Date().getTime();
    const isThisUser = userId === device.subscriptionUserId;
    return {isSubscribed, isThisUser, expiry: new Date(device.subscriptionExpiry), freeExpiry, hasFreeSubscription};
}