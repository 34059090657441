import React from 'react';
import {AuthPermissions} from "./authProvider";
import { Redirect } from 'react-router-dom';

interface UserHomeProps {
    permissions: AuthPermissions
}

const UserHome: React.FC<UserHomeProps> = props => {
    return props.permissions && props.permissions.loggedIn ? (
        <Redirect to={`/users/${props.permissions.id}/show`}/>
    ) : null;
};

export default UserHome