import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {getConfigAsync} from "./config"
import { initAuth } from "./authProvider"
import {setupTranslations} from "./translations";

setupTranslations();

getConfigAsync().then(config => {
    initAuth(config);
    ReactDOM.render(<App config={config}/>, document.getElementById('root'));
});

