import React from 'react'
import { List, Datagrid, TextField, Show, SimpleShowLayout } from 'react-admin';
import { Create, required, minLength, maxLength } from 'react-admin';
import { Edit, EditButton, Toolbar, SaveButton, SimpleForm, TextInput } from 'react-admin';
import { DeleteWithConfirmButton, TitleProps } from 'react-admin';

const TenantForm: React.FC<Omit<React.ComponentProps<typeof SimpleForm>, 'children'>> = props => (
    <SimpleForm {...props} redirect="show">
        <TextInput source="name" validate={[required(), minLength(1), maxLength(50)]}/>
    </SimpleForm>
);

export const TenantCreate: React.FC<React.ComponentProps<typeof Create>> = props => (
    <Create {...props}>
        <TenantForm />
    </Create>
);

// Custom toolbar with just a save button (no delete button as is the default)
const EditToolbar: React.FC<React.ComponentProps<typeof Toolbar>> = props => (
    <Toolbar {...props}>
        <SaveButton/>
        {props.record && <DeleteWithConfirmButton
            confirmTitle={`Are you sure you want to delete ${props.record.name}?`}
            confirmContent={"If this tenant has existing users or tenant members this operation will fail. Be sure to delete all users and tenant members first."}
        />}
    </Toolbar>
);
export const TenantEdit: React.FC<React.ComponentProps<typeof Edit>> = props => (
    <Edit {...props} title={<TenantItemTitle/>}>
        <TenantForm toolbar={<EditToolbar/>}/>
    </Edit>
);

export const TenantList: React.FC<React.ComponentProps<typeof List>> = props => (
    <List {...props}
          bulkActionButtons={false}
          perPage={25}
    >
        <Datagrid rowClick={"show"}>
            <TextField source="name"/>
            <TextField source="id"/>
            {props.permissions && !props.permissions.isReadOnly && <EditButton/>}
        </Datagrid>
    </List>
);


export const TenantShow: React.FC<React.ComponentProps<typeof Show>> = props => (
    <Show {...props} title={<TenantItemTitle/>}>
        <SimpleShowLayout>
            <TextField source="name"/>
            <TextField source="id"/>
        </SimpleShowLayout>
    </Show>
);

const TenantItemTitle: React.FC<TitleProps> = ({record}) => (
    <span>Tenant{record ? `: ${record.name}` : ''}</span>
)