import Button from "./ThemedButton";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import {EditButtonProps, useRecordContext, useRedirect} from "react-admin";

const ThemedEditButton: React.FC<EditButtonProps> = (props) => {
    const redirect = useRedirect();
    const record = useRecordContext()

    return (
        <Button icon={<EditIcon />} onClick={() => redirect(`/${props.resource}/${record.id}`)} label={'Edit'} {...props} />
    )
}

export default ThemedEditButton;