import React, {useEffect} from 'react';
import { Route } from 'react-router-dom';
import { useLogout, CustomRoute } from 'react-admin';
import ChangePassword from './ChangePassword'
import ImportDevices from './ImportDevices'
import UserDeviceScanner from './UserDeviceScanner'
import {WebhookTest} from "./webhooks";

const SignOut: React.FC = () => {
    const logout = useLogout();
    useEffect(() => {
        // We don't want to redirect to /logout after logging out and logging back in, so set third argument to false
        logout(undefined, undefined,false);
    }, [logout]);
    return null;
}

const customRoutes = [
    <Route<CustomRoute> exact path='/logout' component={SignOut} noLayout />,
    <Route<CustomRoute> exact path='/changepassword' component={ChangePassword} />,
    <Route<CustomRoute> exact path='/importdevices' component={ImportDevices} />,
    <Route<CustomRoute> exact path='/userdevicescanner' component={UserDeviceScanner} />,
    <Route<CustomRoute> exact path='/webhooktest' component={WebhookTest} />,
];

export default customRoutes;
