async function runImportDevices(tenantId: string, fileUrl: string, makeRequest: (d: {tenantId: string, csvBase64: string}) => Promise<{json: any}>) {
    const file = await fetch(fileUrl);
    const blob = await file.blob();
    const b64Url: any = await blobToBase64(blob);
    const dataToUpload = b64Url.split(',')[1];
    const response = await makeRequest({tenantId: tenantId, csvBase64: dataToUpload});
    return response.json;
}

const blobToBase64 = async (blob: Blob) => {
  const reader = new FileReader();
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
};

export default runImportDevices;
