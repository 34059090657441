import MuiButton from "@material-ui/core/Button";
import {ButtonProps} from 'react-admin';
import React from "react";

type ThemedButtonProps = ButtonProps & {}

const ThemedButton: React.FC<ThemedButtonProps> = (props) => {
    return (
        <MuiButton
            color={'primary'}
            startIcon={props.icon || props.children}
            {...props}
        >
            {props.label}
        </MuiButton>
    );
}

export default ThemedButton;
