import Button from "./ThemedButton";
import React from "react";
import {EditButtonProps} from "react-admin";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {useTheme} from "@material-ui/styles";
import {useHistory} from "react-router";

const ThemedBackButton: React.FC<EditButtonProps> = (props) => {
    const theme: any = useTheme();
    const history = useHistory();

    return (
        <Button
            variant={'contained'}
            label={'Back'}
            icon={<ArrowBackIcon />}
            onClick={() => history.goBack()}
            style={{backgroundColor: theme.palette.grey['500']}}
            {...props} />
    )
}

export default ThemedBackButton;