import React from "react";
import {Button, useGetOne, useRefresh} from "react-admin";
import GetAppIcon from "@material-ui/icons/GetApp";
import ReplayIcon from "@material-ui/icons/Replay";


interface ImportInProgressProps {
    importId: string;
    resource: string;
    onComplete: (data: any) => void;
}
export const ImportInProgress: React.FC<ImportInProgressProps> = ({resource, importId, onComplete}) => {
    const { data, refetch } = useGetOne(resource, importId);

    React.useEffect(() => {
        const interval = setInterval(() => {
            refetch();
        }, 1000)
        return () => clearInterval(interval);
    }, [refetch])

    React.useEffect(() => {
        if (data && data['isComplete']) {
            onComplete(data);
        }
    }, [data, onComplete])

    return (
        <div>
            {data ? `Processing ${data['totalRows']} rows, ${data['rowsSuccessful']} succeeded, ${data['rowsFailed']} failed.` : 'Loading'}
        </div>
    )
}


type CsvImporterProps = {
    resource: string;
    children(onImportStarted: ((response: any) => void)): React.ReactElement;
}
export const CsvImporter: React.FC<CsvImporterProps> = ({children, resource}) => {
    const refresh = useRefresh();

    const [importId, setImportId] = React.useState('');
    const [isImporting, setIsImporting] = React.useState(false);
    const [isComplete, setIsComplete] = React.useState(false);
    const [importState, setImportState] = React.useState<any>({});

    const onImportStarted = React.useCallback((response: any) => {
        console.log('response', response);
        setImportId(response.data.payload.id);
        setIsImporting(true);
    }, []);

    const onComplete = React.useCallback((data: any) => {
        setIsComplete(true);
        setIsImporting(false);
        setImportState(data);
        refresh();
    }, [refresh])

    const onReset = React.useCallback(() => {
        setImportId('');
        setImportState({});
        setIsImporting(false);
        setIsComplete(false);
    }, [])

    if (isComplete) {
        return (
            <div>
                <div>
                Import complete
                </div>
                <div>
                    Attempted to process {importState['totalRows']} rows, {importState['rowsSuccessful']} succeeded, {importState['rowsFailed']} failed.
                </div>
                <div>
                    {importState['error'] === null ? (
                        <Button
                            variant="contained"
                            label="Download result"
                            component={'a'}
                            href={importState['resultUrl']}
                        >
                            <GetAppIcon />
                        </Button>
                    ) : (
                        `Error while processing: ${importState['error']}`
                    )}
                </div>
                <div>
                <Button
                    variant="contained"
                    label="Reset"
                    onClick={onReset}
                >
                    <ReplayIcon />
                </Button>
                </div>
            </div>
        )
    }

    if (isImporting) {
        return <ImportInProgress resource={resource} importId={importId} onComplete={onComplete} />
    }

    return children(onImportStarted);
}