async function downloadFromBlob(blob: Blob, downloadFilename: string) {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    document.body.appendChild(link);

    try {
        link.style.display = "none";
        link.href = url;
        link.download = downloadFilename;
        link.click();
    } finally {
        setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(url);
        }, 100);
        document.body.removeChild(link);
    }

    return true;
}

export default downloadFromBlob;
