import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
    Show,
    SimpleShowLayout,
    List,
    Datagrid,
    TextField,
    ArrayField,
    ReferenceField,
    TitleProps,
    Edit
} from 'react-admin';
import { Create, SimpleForm, TextInput, SimpleFormIterator, ArrayInput, ReferenceInput, SelectInput, NumberInput } from 'react-admin';
import { maxLength, required } from 'react-admin';
import TruncatedTextField from "./common/TruncatedTextField";
import {minArrayLength} from "./validation";
import ToolbarWithoutDelete from "./common/ToolbarWithoutDelete";

const useStyles = makeStyles({
    hiddenHeaderRow: {
        display: 'none',
    },
});

interface ParameterSetDatagridProps extends React.ComponentProps<typeof Datagrid> {
    showIndex?: boolean;
}
export const ParameterSetDatagrid: React.FC<ParameterSetDatagridProps> = ({showIndex, ...props}) => (
    <Datagrid {...props}>
        {showIndex && <TextField source="index"/>}
        <ReferenceField source="index" reference="parameters" link={false} label="Name" sortable={false}>
            <TextField source="name"/>
        </ReferenceField>
        <TextField source="value" sortable={false}/>
    </Datagrid>
)

export const ParameterProfileLayerDatagrid: React.FC<React.ComponentProps<typeof Datagrid>> = props => {
    const classes = useStyles();
    return (
        <Datagrid rowClick={"show"} {...props}>
            <TextField source="name" />
            <TruncatedTextField source="description" maxLength={50}/>
            <ArrayField source="parameters">
                <ParameterSetDatagrid classes={{headerCell: classes.hiddenHeaderRow}}/>
            </ArrayField>
        </Datagrid>
    );
}

export const ParameterProfileLayerList: React.FC<React.ComponentProps<typeof List>> = props => (
    <List {...props} title={"Parameter Profile Layers"} bulkActionButtons={false} pagination={false}>
        <ParameterProfileLayerDatagrid />
    </List>
);

export const ParameterProfileLayerShow: React.FC<React.ComponentProps<typeof Show>> = props => (
    <Show {...props} title={<ParameterProfileLayerTitle/>}>
        <SimpleShowLayout>
            <TextField source="name"/>
            <TextField source="description" emptyText={'No description provided'} component="pre"/>
            <ArrayField source="parameters">
                <ParameterSetDatagrid />
            </ArrayField>
            <TextField source="id" label={'Layer ID'}/>
        </SimpleShowLayout>
    </Show>
)

type ParameterProfileLayerFormProps = Omit<React.ComponentProps<typeof SimpleForm>, 'children'> & {
    isCreate: boolean;
}
const ParameterProfileLayerForm: React.FC<ParameterProfileLayerFormProps> = ({isCreate, ...props}) => {
    const initialParametersArrayValue = React.useMemo(() => [], []);

    return (
        <SimpleForm {...props}>
            <TextInput source="name" initialValue={''}
                       helperText="Name for this layer"
                       validate={[required(), maxLength(100)]}/>
            <TextInput source="description" initialValue={''} parse={(v: string) => v}
                       fullWidth={true} multiline={true}
                       helperText="Describe what this layer is for"
                       validate={[maxLength(1000)]}/>
            {isCreate && <ArrayInput source="parameters" initialValue={initialParametersArrayValue}
                                     validate={minArrayLength(1, "You must add at least one parameter to this layer")}>
                <SimpleFormIterator>
                    <ReferenceInput source="index" reference="parameters" label="Parameter name"
                                    validate={[required()]}>
                        <SelectInput optionText="name"/>
                    </ReferenceInput>
                    <NumberInput source="value" label="Parameter value" validate={[required()]}/>
                </SimpleFormIterator>
            </ArrayInput>}
        </SimpleForm>
    )
}

export const ParameterProfileLayerEdit: React.FC<React.ComponentProps<typeof Edit>> = (props) => (
    <Edit {...props} title={<ParameterProfileLayerTitle />}>
        <ParameterProfileLayerForm isCreate={false} toolbar={<ToolbarWithoutDelete/>} />
    </Edit>
);

export const ParameterProfileLayerCreate: React.FC<React.ComponentProps<typeof Create>> = (props) => (
    <Create {...props} title={'New parameter profile layer'}>
        <ParameterProfileLayerForm isCreate={true} />
    </Create>
);

const ParameterProfileLayerTitle: React.FC<TitleProps> = ({record}) => (
    <span>Parameter profile layer {record ? record.id : ''}</span>
)
