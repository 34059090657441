import {parse, ParsedUrlQuery} from "querystring";

interface Props {
    location?: {
        search: string;
    };
}

function parseQueryString(props: Props): ParsedUrlQuery {
    if (!props.location) {
        return {};
    }
    let queryString = props.location.search;
    if (queryString && queryString.slice(0, 1) === '?') {
        queryString = queryString.slice(1);
    }
    return parse(queryString)
}

export default parseQueryString