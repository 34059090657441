import {
    ArrayField,
    Datagrid,
    TextField,
    ArrayFieldProps,
} from "react-admin";
import React from "react";

export const TagsField: React.FC<Omit<ArrayFieldProps, 'children'>> = (props) => {
    return (
        <ArrayField {...props}>
            <Datagrid>
                <TextField source="name"/>
                <TextField source="value"/>
            </Datagrid>
        </ArrayField>
    );
}
TagsField.defaultProps = { addLabel: true };
