import React from 'react';
import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Toolbar from '@material-ui/core/Toolbar';
import {useListContext} from 'react-admin';


export const PrevNextPagination: React.FC = () => {
    const { page, perPage, total, setPage } = useListContext();
    const nbPages = Math.ceil(total / perPage) || 1;
    if (nbPages <= 1) {
        return null;
    }
    return (
        <Toolbar>
            {page > 1 &&
                <Button color="primary" key="prev" onClick={() => setPage(page - 1)}>
                    <ChevronLeft />
                    Prev
                </Button>
            }
            {page !== nbPages &&
                <Button color="primary" key="next" onClick={() => setPage(page + 1)}>
                    Next
                    <ChevronRight />
                </Button>
            }
        </Toolbar>
    );
};
