import {TextField, TextFieldProps} from "react-admin";
import React from "react";

type TruncatedTextFieldProps = TextFieldProps & {
    maxLength: number;
}
const TruncatedTextField: React.FC<TruncatedTextFieldProps> = ({maxLength, ...props}) => {
    if (!props.record || !props.source) {
        return null;
    }

    let text: string = props.record[props.source];
    const textParts = text.split('\n');
    text = textParts[0];
    text = (text.length > maxLength+3 || textParts.length > 1) ? text.slice(0, maxLength) + "..." : text;
    return <TextField {...props} record={{...props.record, [props.source]: text}} />
};

export default TruncatedTextField;