import {useField} from 'react-final-form';
import {NumberInput} from "react-admin";
import React from "react";
import Button from "@material-ui/core/Button";
import {HiddenInput} from "./common";

const PanLocationsInput = () => {
    const { input } = useField("calibration.panLocations", {
        parse: (v: string) => v === "" ? null : v,
    });
    if (input.value === "") {
        return <>
            <HiddenInput source="calibration.panLocations" initialValue={null}/>
            <Button variant={'contained'} color={'primary'} onClick={() => input.onChange({target: {value: [0,0,0,0,0,0,0,0,0,0,0,0]}})} >Add pan locations</Button>
        </>
    } else {
        return <div>
            <PanInput panIndex={0} />
            <PanInput panIndex={1} />
            <PanInput panIndex={2} />
            <PanInput panIndex={3} />
            <PanInput panIndex={4} />
            <PanInput panIndex={5} />
            <Button variant={'contained'} color={'primary'} onClick={() => input.onChange({target: {value: null}})} >Clear pan locations</Button>
        </div>
    }
};
export default PanLocationsInput;


interface PanInputProps {
    panIndex: number;
}
const PanInput: React.FC<PanInputProps> = ({panIndex}) => {
    return (
        <div style={{display: 'inline-flex', flexDirection: 'column', marginRight: '20px', marginBottom: '5px', border: '1px solid black', borderRadius: '10px'}}>
            <NumberInput source={`calibration.panLocations[${panIndex}]`} label={`Pan ${panIndex+1}: x`}/>
            <NumberInput source={`calibration.panLocations[${panIndex+6}]`} label={`Pan ${panIndex+1}: y`}/>
        </div>
    )
}