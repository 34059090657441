import downloadFromBlob from "./downloadFromBlob";

async function downloadFromB64(contentB64: string, downloadFilename: string, contentType: string) {
    const byteCharacters = atob(contentB64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], {type: contentType});
    return await downloadFromBlob(blob, downloadFilename);
}

export default downloadFromB64