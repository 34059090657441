import { createTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

export const theme = createTheme({
    palette: {
        primary: blue,
        secondary: blue,
        background: {
            default: '#dfdfdf',
            paper: '#ffffff',
        },
    },
    overrides: {
        MuiButton: {
            textPrimary: {}
        }
    }
});
