import React from 'react';
import { NumberField, TextField, DateField, BooleanField, ReferenceField } from 'react-admin';
import { Show, SimpleShowLayout, TitleProps } from 'react-admin';


export const PingShow: React.FC<React.ComponentProps<typeof Show>> = props => (
    <Show {...props} title={<PingItemTitle/>}>
        <SimpleShowLayout>
            <ReferenceField source="deviceId" reference="devices" link="show">
                <TextField source="id" />
            </ReferenceField>
            <DateField source="timestamp" showTime/>
            <NumberField source="voltagePercent" />
            <NumberField source="usageTimeHours" />
            <NumberField source="ambientTemperatureMin" />
            <NumberField source="ambientTemperatureMax" />
            <NumberField source="firmwareVersionMajor" />
            <NumberField source="firmwareVersionMinor" />
            <NumberField source="parameterVersion" />
            <TextField source="algoVersion.s" label={"Algo version"}/>
            <NumberField source="pingMessageCounter" />
            <BooleanField source="isInitialPing" />
            <TextField source="pingReportMethod"/>
            <NumberField source="numOverheats" />
            <NumberField source="numImageErrors" />
            <NumberField source="numConsecutiveImageErrors" />
            <NumberField source="maxPixelTemperature" />
            <TextField source="id"/>
        </SimpleShowLayout>
    </Show>
);

const PingItemTitle: React.FC<TitleProps> = ({record}) => (
    <span>Ping {record ? `at ${new Date(record.timestamp).toISOString()} for ${record.deviceId}` : ''}</span>
)
