import React from "react";
import {SaveButton, Toolbar} from "react-admin";

const ToolbarWithoutDelete: React.FC<React.ComponentProps<typeof Toolbar>> = (props) => {
    return (
        <Toolbar {...props}>
            <SaveButton />
        </Toolbar>
    );
};

export default ToolbarWithoutDelete;
