import React from 'react';
import parseQueryString from "./common/parseQueryString";
import {ParameterSetDatagrid} from "./parameterProfileLayers";
import { AutocompleteInput } from 'react-admin';
import { Show, SimpleShowLayout, Datagrid, ArrayField, DateField, TextField, NumberField, ReferenceField } from 'react-admin';
import { Create, SimpleForm, ReferenceInput } from 'react-admin';
import { Identifier, required } from 'react-admin';

export const ParameterUpdateDatagrid: React.FC<React.ComponentProps<typeof Datagrid>> = (props) => (
    <Datagrid {...props} rowClick={"show"}>
        <TextField source={"updateId"} sortable={false}/>
        <TextField source={"minimumDeviceUpdateId"} sortable={false}/>
        <TextField source={"statusHistory[0].status"} sortable={false} label="Status"/>
        <ReferenceField source="sourceProfileId" reference="parameterprofiles" label="Parameter profile" link="show">
            <TextField source="name" />
        </ReferenceField>
    </Datagrid>
)

export const ParameterUpdateShow: React.FC<React.ComponentProps<typeof Show>> = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source={"updateId"} label={"Update ID"}/>
            <TextField source={"deviceId"} label={"Device ID"}/>
            <TextField source={"minimumDeviceUpdateId"} label={"Minimum device update ID"}/>
            <NumberField source={"sendCount"} />
            <TextField source={"smsId"} label={"Outgoing SMS ID"}/>
            <ReferenceField source="sourceProfileId" reference="parameterprofiles" label="Profile Name" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <ArrayField source={"statusHistory"} label={"Status history (most recent first)"}>
                <Datagrid>
                    <DateField source={"timestamp"} showTime sortable={false}/>
                    <TextField source={"status"} sortable={false}/>
                </Datagrid>
            </ArrayField>
            <ArrayField source={"parameters"} label={"Parameters included in text message"}>
                <ParameterSetDatagrid showIndex/>
            </ArrayField>
            <TextField source={"id"} label={"Unique ID"}/>
        </SimpleShowLayout>
    </Show>
)

export const ParameterUpdateCreate: React.FC<React.ComponentProps<typeof Create>> = (props) => {
    const {device_id: deviceId, profile_id: sourceProfileId} = parseQueryString(props);

    return (
        <Create {...props} title={"Create Parameter Update"}>
            <SimpleForm
                initialValues={{deviceId, sourceProfileId}}
                redirect={(basePath: string, id: Identifier, data?: { deviceId?: string; }) => data && data.deviceId ? `/devices/${data.deviceId}/show/updates` : '/devices'}>
                <ReferenceInput label="Device ID" source="deviceId" reference="devices">
                    <AutocompleteInput optionText="id" validate={required()} disabled={deviceId !== undefined} />
                </ReferenceInput>
                <ReferenceInput label="Parameter Profile Name" source="sourceProfileId" reference="parameterprofiles">
                    <AutocompleteInput optionText="name" validate={required()} disabled={sourceProfileId !== undefined} />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};
