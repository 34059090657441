import React from 'react';
import { List, Filter, DateInput, Datagrid, TextField, DateField } from 'react-admin';
import { PrevNextPagination } from './prevNextPagination';


const RawMessageFilter: React.FC<Omit<React.ComponentProps<typeof Filter>, 'children'>> = (props) => (
    <Filter {...props}>
        <DateInput label="Since" source="since_date" alwaysOn/>
        <DateInput label="Before" source="before_date" alwaysOn/>
    </Filter>
);

export const RawMessageList: React.FC<React.ComponentProps<typeof List>> = props => (
    <List {...props} title={"Incoming SMS"} bulkActionButtons={false}
          filters={<RawMessageFilter/>}
          pagination={<PrevNextPagination/>}
          perPage={25}
          sort={{ field: 'timestamp', order: 'DESC' }}
    >
        <Datagrid>
            <DateField source="timestamp" showTime />
            <TextField source="source" sortable={false} />
            <MessageTypeField sortable={false} />
            <TextField source="text" sortable={false} />
        </Datagrid>
    </List>
);

const messageTypeMapping: {[n: number]: string} = {
    0: 'Ping',
    1: 'Alert',
    2: 'Alert cleared',
    3: 'First use',
};

const getIntAtOffset = (text: string, offset: number): number => parseInt(text.slice(offset*2, offset*2+2), 16)

const MessageTypeField: React.FC<React.ComponentProps<typeof TextField>> = (props) => {
    if (!props.record) {
        return null;
    }

    const text = props.record.text;
    const messageTypeInt = getIntAtOffset(text, 0);
    let messageType = messageTypeMapping[messageTypeInt];
    if (messageTypeInt === 0) {
        if (getIntAtOffset(text, 1) >= 3) {
            if (!!(getIntAtOffset(text, 16) & 0x80)) {
                messageType = 'Ping (initial)';
            }
        } else if (getIntAtOffset(text, 1) === 2) {
            if (!!(getIntAtOffset(text, 12) & 0x80)) {
                messageType = 'Ping (initial)';
            }
        } else {
            messageType = 'Ping (old)';
        }
    }
    return <TextField {...props} record={{...props.record, messageType: messageType}}/>;
};
MessageTypeField.defaultProps = {
    source: "messageType",
};
