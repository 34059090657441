import React, {useContext} from 'react';
import LineGraphField from "./LineGraphField";
import useTheme from "@material-ui/styles/useTheme";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    FilterProps,
    ListProps,
    TitleProps,
    ShowController,
    ShowView,
    InjectedFieldProps,
    Record,
    useDataProvider,
    useRefresh
} from 'react-admin';
import { Filter, DateInput, ReferenceInput, AutocompleteInput, DeleteButton } from 'react-admin';
import { Show, SimpleShowLayout } from 'react-admin';
import {ConfigContext} from "./App";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThemedButton from "./common/ThemedButton";
const { PrevNextPagination } = require('./prevNextPagination');


const AlertFilter: React.FC<Omit<FilterProps, 'children'>> = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Device ID" source="device_id" reference="devices">
            <AutocompleteInput optionText="id"/>
        </ReferenceInput>
        <DateInput label="Since" source="since_date" />
        <DateInput label="Before" source="before_date" />
    </Filter>
);


export const AlertList: React.FC<ListProps> = props => (
    <List {...props} bulkActionButtons={false}
          filters={<AlertFilter/>}
          pagination={<PrevNextPagination/>}
          perPage={25}
          sort={{ field: 'timestamp', order: 'DESC' }}
    >
        <Datagrid rowClick={"show"}>
            <ReferenceField source="device_id" reference="devices" link="show" sortable={false}>
                <TextField source="id" />
            </ReferenceField>
            <DateField source="timestamp" showTime/>
            <TextField source="category" sortable={false}/>
        </Datagrid>
    </List>
);

const TimeToClearField: React.FC<React.ComponentProps<typeof TextField>> = (props) => {
    if (!props.record) {
        return null;
    }
    const secondsToClear = props.record.clearedTimestamp ? ((props.record.clearedTimestamp - props.record.timestamp)/1000).toString() : null;
    return <TextField {...props} record={{...props.record, secondsToClear}} source={"secondsToClear"}/>
};
TimeToClearField.defaultProps = {
    addLabel: true,
};

const AlertLocationField: React.FC<InjectedFieldProps> = (props) => {
    if (!props.record) {
        return null;
    }
    return (
        <svg viewBox="0 0 17 17" style={{height: '200px', width: '200px', backgroundColor: 'gray'}}>
            <circle cx={props.record['panLocation'][0]} cy={props.record['panLocation'][1]} r={1} fill="red" />
        </svg>
    );
};

interface GiveAlertFeedbackButtonProps {
    record: Record | undefined;
}
const GiveAlertFeedbackButton: React.FC<GiveAlertFeedbackButtonProps> = ({record}) => {
    const refresh = useRefresh();
    const theme: any = useTheme();

    const dataProvider = useDataProvider();
    const config = useContext(ConfigContext);
    const alertId = record && record.id as string

    const tokenExpired = record && (record.feedbackTokenExpiry < new Date().getTime());

    React.useEffect(() => {
        if (tokenExpired) {
            (dataProvider as any).refreshAlertFeedbackToken(record.id.toString()).then(() => refresh());
        }
    }, [tokenExpired, dataProvider, record, refresh])

    if (record === undefined || alertId === undefined || tokenExpired) {
        return null;
    }

    const url = `${config.FEEDBACK_URL}?token=${record.feedbackToken}`;
    return (
        <a href={url} target={'_blank'} rel={'noreferrer'}>
            <ThemedButton
                variant="contained"
                label="Give feedback"
                style={{backgroundColor: theme.palette.warning.main}}
            >
                <ThumbUpIcon />
            </ThemedButton>
        </a>
    )
}

export const AlertShow: React.FC<React.ComponentProps<typeof Show>> = props => (
    <ShowController {...props}>
        {controllerProps => (
            <ShowView {...props} {...controllerProps} title={<AlertItemTitle/>}>
                <SimpleShowLayout>
                    {props.permissions && props.permissions.isUser && <GiveAlertFeedbackButton record={controllerProps.record} />}
                    <ReferenceField source="device_id" reference="devices" link="show">
                        <TextField source="id" />
                    </ReferenceField>
                    <TextField source="category"/>
                    <DateField source="timestamp" showTime/>
                    <DateField source="clearedTimestamp" showTime emptyText={"Cleared message not received"}/>
                    <TimeToClearField label={"Seconds to clear"} emptyText={"Cleared message not received"}/>
                    {props.permissions && props.permissions.isTenantMember && <TextField source="panLocation"/>}
                    {props.permissions && props.permissions.isTenantMember && <AlertLocationField />}
                    {props.permissions && props.permissions.isTenantMember && <TextField source="numPans" label={"Number of pans"}/>}
                    <LineGraphField xLabel={"Time"} yLabel={"Approx temperature (C)"} xSource="panTemperatureHistoryTimes" ySource={"panTemperatureHistory"} />
                    <TextField source="id" label={'Alert ID'}/>
                    {props.permissions && props.permissions.isTenantMember && controllerProps.record && controllerProps.record.feedback && <TextField source={'feedback.falseAlarm'} label={'Feedback: False alarm'} />}
                    {props.permissions && props.permissions.isTenantMember && controllerProps.record && controllerProps.record.feedback && <TextField source={'feedback.isMultiple'} label={'Feedback: Happened multiple times'} />}
                    {props.permissions && props.permissions.isTenantMember && controllerProps.record && controllerProps.record.feedback && <TextField source={'feedback.stoveType'} label={'Feedback: Stove type'} />}
                    {props.permissions && props.permissions.isTenantMember && controllerProps.record && controllerProps.record.feedback && <TextField source={'feedback.otherStoveType'} label={'Feedback: Other stove type'} />}
                    {props.permissions && props.permissions.isTenantMember && controllerProps.record && controllerProps.record.feedback && <TextField source={'feedback.hasPan'} label={'Feedback: Has pan'} />}
                    {props.permissions && props.permissions.isTenantMember && controllerProps.record && controllerProps.record.feedback && <TextField source={'feedback.stoveOn'} label={'Feedback: Stove on'} />}
                    {props.permissions && props.permissions.isTenantMember && controllerProps.record && controllerProps.record.feedback && <TextField component={'pre'} source={'feedback.usageDescription'} label={'Feedback: Usage description'} />}
                    {props.permissions && props.permissions.isTenantMember && controllerProps.record && controllerProps.record.feedback && <TextField source={'feedback.adjustSensitivity'} label={'Feedback: Adjust sensitivity'} />}
                    {props.permissions && props.permissions.isRoot && !props.permissions.isReadOnly && <DeleteButton />}
                </SimpleShowLayout>
            </ShowView>
        )}
    </ShowController>
);

const AlertItemTitle: React.FC<TitleProps> = ({record}) => (
    <span>Alert {record ? `at ${new Date(record.timestamp).toISOString()} for ${record.device_id}` : ''}</span>
)
