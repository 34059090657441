import React from 'react';
import { NumberField, TextField, DateField, ReferenceField } from 'react-admin';
import { Show, SimpleShowLayout, TitleProps } from 'react-admin';


export const FirstUseShow: React.FC<React.ComponentProps<typeof Show>> = props => (
    <Show {...props} title={<FirstUseItemTitle/>}>
        <SimpleShowLayout>
            <ReferenceField source="deviceId" reference="devices" link="show">
                <TextField source="id" />
            </ReferenceField>
            <DateField source="timestamp" showTime/>
            <NumberField source="firmwareVersionMajor" />
            <NumberField source="firmwareVersionMinor" />
            <TextField source="id"/>
        </SimpleShowLayout>
    </Show>
);

const FirstUseItemTitle: React.FC<TitleProps> = ({record}) => (
    <span>First use {record ? `at ${new Date(record.timestamp).toISOString()} for ${record.deviceId}` : ''}</span>
)
