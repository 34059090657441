import React from "react";
import { TextInput } from 'react-admin';

export const HiddenInput: React.FC<React.ComponentProps<typeof TextInput>> = props => (
    <div style={{display: 'none'}}>
        <TextInput {...props} parse={(v: string) => JSON.parse(v)} format={(v: any) => JSON.stringify(v)}/>
    </div>
)

export const NullableTextInput: React.FC<React.ComponentProps<typeof TextInput>> = props => (
    <TextInput {...props} parse={(v: string) => v === "" ? null : v}/>
);

export class UnreachableCaseError extends Error {
    constructor(val: never) {
        super(`Unreachable case: ${(val as any).type}`);
    }
}
