import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {AuthPermissions} from "./authProvider";
import {usePermissions, useRefresh} from "react-admin";
import LockIcon from '@material-ui/icons/Lock';
const { useGetList, useDataProvider, useRedirect } = require('react-admin');
const { AppBar, UserMenu, MenuItemLink, MenuItemLinkProps } = require('react-admin');


const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

export function useTenantId() {
    const { data } = useGetList('tenants', {page: 1, perPage: 100}, {field: 'id', order: 'DESC'}, {});
    const dataProvider = useDataProvider();
    const [tenantId, setTenantId] = useState("");
    useEffect(() => {
        dataProvider.tenantId = tenantId ? tenantId : "";
    });
    return [tenantId, data, setTenantId]
}

const TenantSelector: React.FunctionComponent<TenantSelectorProps> = ({tenantId, allTenants, setTenantId}) => {
    const redirect = useRedirect();
    const refresh = useRefresh();
    const tenants = Object.values(allTenants);
    return (
        <select value={tenantId} onChange={ e => {
            setTenantId(e.target.value);
            redirect('/');
            refresh();
        } }>
            <option key={"blank"} value={""}>View as self</option>
            {tenants.map(tenant => <option key={tenant.id} value={tenant.id}>Become Tenant: {tenant.name}</option>)}
        </select>
    );
};
type TenantSelectorProps = {
  tenantId: string
  allTenants: Map<string, {id: string, name: string}>,
  setTenantId: (t: string) => void
};

const CustomAppBar: React.FC = props => {
    const classes = useStyles();
    const { permissions }: {permissions?: AuthPermissions} = usePermissions();

    return (
        <AppBar {...props} userMenu={<CustomUserMenu/>}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            {permissions && permissions.loggedIn && !permissions.isUser && permissions.isRoot && <OptionalTenantSelector/>}
            <span className={classes.spacer} />
        </AppBar>
    );
};

const OptionalTenantSelector: React.FC = () => {
    const [tenantId, allTenants, setTenantId] = useTenantId();
    const { permissions }: {permissions?: AuthPermissions} = usePermissions();

    return permissions && permissions.loggedIn && permissions.isRoot ? (
        <TenantSelector tenantId={tenantId} allTenants={allTenants} setTenantId={setTenantId}/>
    ) : null;
}


const ChangePasswordMenu: React.FC<typeof MenuItemLinkProps> = React.forwardRef(({ onClick }, ref) => (
    <MenuItemLink
        ref={ref}
        to="/changepassword"
        primaryText="Change password"
        leftIcon={<LockIcon />}
        onClick={onClick} // close the menu on click
    />
));

const CustomUserMenu: React.FC = (props) => {
    return (
        <UserMenu {...props}>
            <ChangePasswordMenu/>
        </UserMenu>
    )
};

export default CustomAppBar;