import React from 'react';
import { v4 as uuidv4} from 'uuid';
import {useDataProvider, useGetList} from "react-admin";
import {DecodeHintType, useZxing} from "react-zxing";
import {useDropzone} from 'react-dropzone'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from "@material-ui/core/Button";
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from "@material-ui/core";
import {makePostRequest} from "./dataProvider";

const trackingCsvSpec = {
    "PARCEL_ID": ["Tracking number, which can be extracted from the postage label", "SE236323405GB"],
    "CUSTOMER_ID": ["Unique customer ID, matches the ID of a row in the customer lookup CSV file", "123456"],
}
const customerCsvSpec = {
    "CUSTOMER_ID": ["Unique ID for this customer", "123456"],
    "FIRST_NAME": ["First name of the customer", "John"],
    "SURNAME": ["Surname of the customer", "Smith"],
    "EMAIL": ["Email address of the customer. It is assumed that this email is verified.", "john.smith@example.com"],
    "ADDRESS_LINE_1": ["First line of address", "Flat 4"],
    "ADDRESS_LINE_2": ["Second line of address. May be blank.", "29 Example Road"],
    "ADDRESS_LINE_3": ["Third line of address. May be blank.", ""],
    "ADDRESS_CITY": ["City. May be blank.", "London"],
    "ADDRESS_REGION": ["For the UK, this is the county. May be blank.", "Isle of Wight"],
    "ADDRESS_POSTAL_CODE": ["Postcode", "AB12 3CD"],
    "ADDRESS_COUNTRY_ISO2": ["ISO2 2-letter country code", "GB"],
    "PRIMARY_PHONE_NUMBER": ["Main contact phone number for the customer. This will also be added to their device", "+447777888999"],
    "ADDITIONAL_PHONE_NUMBER_1": ["Additional phone number to be added to their device", "+447777888999"],
    "ADDITIONAL_PHONE_NUMBER_2": ["Additional phone number to be added to their device", "+447777888999"],
    "ADDITIONAL_PHONE_NUMBER_3": ["Additional phone number to be added to their device", "+447777888999"],
    "ADDITIONAL_PHONE_NUMBER_4": ["Additional phone number to be added to their device", "+447777888999"],
    "ADDITIONAL_PHONE_NUMBER_5": ["Additional phone number to be added to their device", "+447777888999"],
    "ADDITIONAL_PHONE_NUMBER_6": ["Additional phone number to be added to their device", "+447777888999"],
}


const TRACKING_HEADERS = Object.keys(trackingCsvSpec);
const CUSTOMER_HEADERS = Object.keys(customerCsvSpec);

type GenericCsvItem = {[key: string]: string}
type Customer = {[k in keyof typeof customerCsvSpec]: string}
type Tracking = {[k in keyof typeof trackingCsvSpec]: string}


type CsvDropzoneProps<T extends GenericCsvItem> = {
    onFileLoaded: (content: T[]) => void;
    expectedHeaders: string[];
}
function CsvDropzone<T extends GenericCsvItem>({onFileLoaded, expectedHeaders}: CsvDropzoneProps<T>) {
    const [errorText, setErrorText] = React.useState<string | null>(null);
    const [loadedText, setLoadedText] = React.useState<string | null>(null);

    const onDrop = React.useCallback((acceptedFiles: File[]) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const fileContentArrayBuffer = reader.result;
                const fileContentString = new TextDecoder("utf8").decode(fileContentArrayBuffer as ArrayBuffer);
                const fileMatrix = fileContentString.trim().split("\n").map(s => s.trim().split(',')).map(s => s.map(t => t.trim()));
                const columnHeaders = fileMatrix[0];

                const columnsValid = expectedHeaders.every(k => columnHeaders.indexOf(k) > -1);
                if (!columnsValid) {
                    setLoadedText(null);
                    setErrorText("Invalid columns. Expected " + expectedHeaders.toString().replaceAll(",", ", "));
                } else {
                    const data = fileMatrix.slice(1);
                    const objectArray = data.map(d => Object.fromEntries(d.map((d, i) => [columnHeaders[i], d])));
                    setErrorText(null);
                    setLoadedText(`Loaded ${objectArray.length} ${objectArray.length === 1 ? 'record' : 'records'}. Found required columns ${expectedHeaders.toString().replaceAll(",", ", ")}`)
                    onFileLoaded(objectArray as T[])
                }
            }
            reader.readAsArrayBuffer(file)
        })

    }, [expectedHeaders, onFileLoaded])
    const {getRootProps, getInputProps} = useDropzone({
        onDrop: onDrop,
        accept: '.csv',
        multiple: false,
    })

    return (
        <div>
            <div {...getRootProps()} style={{border: '1px solid black', borderRadius: '5px', paddingLeft: '10px'}}>
                <input {...getInputProps()} />
                <p>Drop file here, or click to select file</p>
            </div>
            {errorText && <p style={{color: "red"}}>{errorText}</p>}
            {loadedText && <p style={{color: "darkgreen"}}>{loadedText}</p>}
        </div>
    )
}

async function checkPhoneNumber(phoneNumber: string): Promise<{isValid: boolean, isMobile: boolean}> {
    if (!/^\+[1-9]\d{6,14}$/.test(phoneNumber)) {
        // We're not ok, don't bother checking with the API
        return {isValid: false, isMobile: false};
    }
    const response = await makePostRequest("validatephonenumber", {'phoneNumber': phoneNumber});
    return response.json;
}

async function checkPhoneNumbers(customer: Customer): Promise<{phoneNumber: string, isValid: boolean, isMobile: boolean}[]> {
    const numbersToCheck = [
        customer.PRIMARY_PHONE_NUMBER,
        customer.ADDITIONAL_PHONE_NUMBER_1,
        customer.ADDITIONAL_PHONE_NUMBER_2,
        customer.ADDITIONAL_PHONE_NUMBER_3,
        customer.ADDITIONAL_PHONE_NUMBER_4,
        customer.ADDITIONAL_PHONE_NUMBER_5,
        customer.ADDITIONAL_PHONE_NUMBER_6,
    ].filter(x => x.length > 0);

    const validations = await Promise.all(numbersToCheck.map(x => checkPhoneNumber(x)));
    return validations.map((v, i) => ({...v, phoneNumber: numbersToCheck[i]}));
}

type DataCreatorProps = {
    customer: Customer;
    deviceId: string;
    onNext: () => void;
}
export const DataCreator: React.FC<DataCreatorProps> = ({customer, deviceId, onNext}) => {
    const dataProvider = useDataProvider();
    const [isCreatingUser, setIsCreatingUser] = React.useState<boolean>(false);
    const [userId, setUserId] = React.useState<string | null>(null);
    const [isCreatingDeviceLink, setIsCreatingDeviceLink] = React.useState<boolean>(false);
    const [deviceLinkSuccess, setDeviceLinkSuccess] = React.useState<boolean>(false);
    const [isCheckingPhoneNumbers, setIsCheckingPhoneNumbers] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string | null>(null);

    const {ids: existingUserIds, data: existingUsers, loaded: existingUsersLoaded} = useGetList('users', undefined, undefined, {search: customer.EMAIL})

    const onReset = React.useCallback(() => {
        setIsCreatingUser(false);
        setUserId(null);
        setIsCreatingDeviceLink(false);
        setDeviceLinkSuccess(false);
        setIsCheckingPhoneNumbers(false);
        setError(null);
        onNext();
    }, [onNext]);

    const onSubmit = React.useCallback(async () => {
        setError(null);

        // Check phone numbers
        let phoneNumberValidation: {phoneNumber: string, isValid: boolean, isMobile: boolean}[] = [];
        try {
            setIsCheckingPhoneNumbers(true);
            phoneNumberValidation = await checkPhoneNumbers(customer);
            const invalidNumbers = phoneNumberValidation.filter(v => !v.isValid);
            if (invalidNumbers.length > 0) {
                setError("Error validating phone numbers: " + invalidNumbers.map(v => v.phoneNumber).toString());
                return;
            }
        } catch (err) {
            setError(`Error validating phone numbers: ${err}`)
            return;
        } finally {
            setIsCheckingPhoneNumbers(false);
        }

        // Create user
        let newUserId: string | null = null;
        if (userId !== null) {
            // If we are on a retry and we already have the user ID
            newUserId = userId;
        } else {

            try {
                setIsCreatingUser(true);
                const {data: user} = await dataProvider.create('users', {
                    data: {
                        givenName: customer.FIRST_NAME,
                        familyName: customer.SURNAME,
                        phone_number: customer.PRIMARY_PHONE_NUMBER,
                        email: customer.EMAIL,
                        emailVerified: true,
                        address: {
                            line1: customer.ADDRESS_LINE_1,
                            line2: customer.ADDRESS_LINE_2.length > 0 ? customer.ADDRESS_LINE_2 : null,
                            line3: customer.ADDRESS_LINE_3.length > 0 ? customer.ADDRESS_LINE_3 : null,
                            city: customer.ADDRESS_CITY.length > 0 ? customer.ADDRESS_CITY : null,
                            region: customer.ADDRESS_REGION.length > 0 ? customer.ADDRESS_REGION : null,
                            postalCode: customer.ADDRESS_POSTAL_CODE,
                            countryIso2: customer.ADDRESS_COUNTRY_ISO2,
                        }
                    }
                })
                newUserId = user.id as string;
                setUserId(newUserId as string);
            } catch (err) {
                setError(`Error creating user: ${err}`)
                return;
            } finally {
                setIsCreatingUser(false);
            }

        }

        // Create user-device link
        try {
            if (newUserId === null) {
                return;
            }
            setIsCreatingDeviceLink(true);
            await dataProvider.create('userdevices', {
                data: {
                    userId: newUserId,
                    deviceId: deviceId,
                    friendlyName: null,
                    contactNumbers: phoneNumberValidation.map(v => ({
                        phoneNumber: v.phoneNumber,
                        isPhone: !v.isMobile,
                        isSms: v.isMobile,
                    })),
                    calibration: {
                        hobLayoutId: null,
                        panLocations: null,
                    }
                }
            })
            setDeviceLinkSuccess(true);
        } catch (err) {
            setError(`Error creating device link: ${err}`)
            return;
        } finally {
            setIsCreatingDeviceLink(false);
        }
    }, [customer, deviceId, dataProvider, userId]);

    if (existingUserIds.length > 0 && userId === null) {
        return (
            <div>
                <p>{'Users were found with matching email addresses. Email addresses must be unique.'}</p>
                {existingUserIds.map(existingUserId => (
                    <div style={{display: 'flex', alignItems: 'center', border: '1px solid black', borderRadius: '5px', padding: '5px'}}>
                        <div><p>Existing user: {`${existingUsers[existingUserId].givenName} ${existingUsers[existingUserId].familyName}, ${existingUsers[existingUserId].email}, ${existingUsers[existingUserId].address ? existingUsers[existingUserId].address.line1 : 'No address recorded'}`}</p></div>
                        <div style={{'marginLeft': '5px'}}><Button variant={'contained'} color={'primary'} onClick={() => setUserId(existingUserId as string)}>Link device to this existing user</Button></div>
                    </div>
                ))}
            </div>
        )
    }

    const showReset = existingUsersLoaded && !isCreatingUser && !isCreatingDeviceLink && (error !== null || (userId !== null && deviceLinkSuccess));
    const showSubmit = existingUsersLoaded && !isCreatingUser && !isCreatingDeviceLink && (error === null && !deviceLinkSuccess);
    const showRetry = existingUsersLoaded && !isCreatingUser && !isCreatingDeviceLink && (error !== null);

    return (
        <div>
            {showSubmit && <Button variant={'contained'} color={'primary'} onClick={onSubmit}>Submit</Button>}
            {showReset && <Button variant={'contained'} color={'primary'} onClick={onReset}>Scan next Pippa</Button>}
            {showRetry && <Button variant={'contained'} color={'primary'} onClick={onSubmit}>Retry</Button>}
            {error && <p style={{color: 'red'}}>{error}</p>}
            <p>{`Check phone numbers: ${isCheckingPhoneNumbers ? 'in progress' : 'not in progress'}`}</p>
            <p>{`Create user: ${isCreatingUser ? 'in progress' : userId !== null ? `Created user ${userId} (${customer.EMAIL})` : 'not started'}`}</p>
            <p>{`Create device link: ${isCreatingDeviceLink ? 'in progress' : deviceLinkSuccess ? `Linked device ${deviceId.slice(0, 7)}` : 'not started'}`}</p>
        </div>
    );
}

export const BarcodeScanner = () => {
    const [customer, setCustomer] = React.useState<Customer | null>(null);
    const [deviceId, setDeviceId] = React.useState<string | null>(null);
    const [customerData, setCustomerData] = React.useState<{[customerId: string]: Customer} | null>(null);
    const [trackingData, setTrackingData] = React.useState<{[trackingId: string]: string} | null>(null);

    const [lookupError, setLookupError] = React.useState<string | null>(null);

    const csvReady = customerData !== null && trackingData !== null;
    const scansReady = customer !== null && deviceId !== null;

    const onPackageScanned = React.useCallback((barcodeData: string) => {
        if (trackingData === null || customerData === null) {
            return;
        }
        const customerId = trackingData[barcodeData];
        if (customerId === undefined) {
            setLookupError("Failed to lookup customer ID from tracking ID. Tracking ID was " + barcodeData);
            return;
        }
        const lookedUpCustomer = customerData[customerId];
        if (lookedUpCustomer === undefined) {
            setLookupError("Failed to lookup customer from customer ID. Customer ID was " + customerId);
            return;
        }
        setLookupError(null);
        setCustomer(lookedUpCustomer);
    }, [trackingData, customerData]);

    const onPippaScanned = React.useCallback((qrData: string) => {
        setDeviceId(qrData);
    }, []);

    const onNext = React.useCallback(() => {
        setCustomer(null);
        setDeviceId(null);
        setLookupError(null);
    }, [])

    const hints = new Map<DecodeHintType, any>();
    // hints.set(DecodeHintType.TRY_HARDER, true);

    const videoPaused = !csvReady || scansReady;
    const { ref } = useZxing({
        onResult(result) {
            const barcodeData = result.getText();
            console.log('Read barcode', barcodeData);

            if (/^[A-Z0-9]{40}$/.test(barcodeData)) {
                onPippaScanned(barcodeData);
                return;
            }
            if (barcodeData.length === 84 && barcodeData.split(" ").filter(x => x.length > 0).length === 6) {
                const extractedTrackingNumber = barcodeData.split(" ").map(s => s.trim()).filter(s => s.length > 0)[2]
                if (/^[A-Z]{2}[0-9]{9}[A-Z]{2}.?.?.?$/.test(extractedTrackingNumber)) {
                    onPackageScanned(extractedTrackingNumber.slice(0, 13))
                    return;
                }
            }

            // Do nothing, invalid barcode
            console.log("Got barcode data but was not recognized as a valid tracking label or Pippa label", barcodeData.length, barcodeData)
        },
        paused: videoPaused,
        hints: hints,
    });

    return (
        <div>
            <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography variant="subtitle2">Select CSV Files</Typography>
                </AccordionSummary>
                <AccordionDetails style={{flexDirection: 'column'}}>
                    <p>Select tracking lookup CSV file</p>
                    <CsvDropzone onFileLoaded={(data: Tracking[]) => {
                        const trackingToCustomerLookup = Object.fromEntries(data.map(d => [d.PARCEL_ID, d.CUSTOMER_ID]));
                        setTrackingData(trackingToCustomerLookup)
                    }} expectedHeaders={TRACKING_HEADERS} />
                    <p>Select customer lookup CSV file</p>
                    <CsvDropzone onFileLoaded={(data: Customer[]) => {
                        // Generate unique non-existing email address for any customers who do not have an email address
                        for (let d of data) {
                            if (d.EMAIL === "") {
                                d.EMAIL = `unknown-${uuidv4().replaceAll('-', '')}@example.com`
                            }
                        }
                        const customerIdToCustomerLookup = Object.fromEntries(data.map(d => [d.CUSTOMER_ID, d]));
                        setCustomerData(customerIdToCustomerLookup);
                    }} expectedHeaders={CUSTOMER_HEADERS} />
                </AccordionDetails>
            </Accordion>
            <CsvSpecification />
            {csvReady && (
                <>
                    <p style={{fontSize: 32, fontWeight: 'bold'}}>{'Scan barcodes'}</p>
                    <p>Customer: {lookupError !== null ? lookupError : customer === null ? "Not scanned" : `${customer.FIRST_NAME} ${customer.SURNAME}, ${customer.EMAIL}, ${customer.ADDRESS_LINE_1}`}</p>
                    <p>Pippa QR Code: {deviceId === null ? "Not scanned" : deviceId}</p>
                </>
            )}
            <video ref={ref} style={{width: '100%', maxWidth: '300px', display: videoPaused ? 'none' : 'initial'}}/>
            {scansReady && (
                <DataCreator customer={customer} deviceId={deviceId} onNext={onNext}/>
            )}
        </div>
    );
};

const CsvSpecification = () => {
    return <>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography variant="subtitle2">Tracking CSV File Specification</Typography>
            </AccordionSummary>
            <AccordionDetails style={{flexDirection: 'column'}}>
                <Typography>The following columns are expected in the CSV file. Some columns can accept multiple names.</Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Column Name</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Example</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(trackingCsvSpec).map(([k, v]) => (
                                <TableRow key={k}>
                                    <TableCell component="th" scope="row">
                                        {k}
                                    </TableCell>
                                    <TableCell>{v[0]}</TableCell>
                                    <TableCell>{v[1]}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography style={{marginTop: 10}}>{'\u2022'} Extra columns are OK, they will be ignored</Typography>
                <Typography>{'\u2022'} Blank values are not OK, all values are required</Typography>
                <Typography>{'\u2022'} There should be a header row with the column names</Typography>
                <Typography>{'\u2022'} Columns can be in any order, as long as the column names are correct</Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography variant="subtitle2">Customer CSV File Specification</Typography>
            </AccordionSummary>
            <AccordionDetails style={{flexDirection: 'column'}}>
                <Typography>The following columns are expected in the CSV file</Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Column Name</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Example</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(customerCsvSpec).map(([k, v]) => (
                                <TableRow key={k}>
                                    <TableCell component="th" scope="row">
                                        {k}
                                    </TableCell>
                                    <TableCell>{v[0]}</TableCell>
                                    <TableCell>{v[1]}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography style={{marginTop: 10}}>{'\u2022'} Extra columns are OK, they will be ignored</Typography>
                <Typography>{'\u2022'} Blank values are OK where specified</Typography>
                <Typography>{'\u2022'} There should be a header row with the column names</Typography>
                <Typography>{'\u2022'} Columns can be in any order, as long as the column names are correct</Typography>
            </AccordionDetails>
        </Accordion>
    </>
}

export default BarcodeScanner