import { I18n } from "aws-amplify";

// Overwrite error messages
export function setupTranslations() {
    I18n.putVocabularies({
        en: {
            "Unable to login because of security reasons.":
                "Login attempt blocked due to security reasons. To unblock your account, check your email and confirm this attempt was you.",
        },
    });
}
