import { TranslationMessages } from 'ra-core';
import englishMessages from 'ra-language-english';
import polyglotI18nProvider from 'ra-i18n-polyglot';

interface TranslationMap {
    [key: string]: TranslationMessages;
}
const messages: TranslationMap = {
    en: {
        ...englishMessages,
        custom: {
            drop_csv_file: 'Drop a CSV file to upload, or click to select it'
        }
    },
}

export default polyglotI18nProvider((locale) => messages[locale], 'en', {
    allowMissing: true,
});
